import React from 'react'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'

const PrivacyPolicy = () => {
  return (
    <>
          <div className=' max-w-screen-xl mx-auto mt-32 flex flex-col gap-3'>
        <h2 className=' text-3xl font-bold'>Privacy Policy</h2>
        <p>At Book Puja (legal name DEVSTHANA LLP), we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and disclose your information when you use our website. By accessing or using our website, you consent to the collection, use, and disclosure of your personal information as described in this policy.</p>
        <ol className=' list-decimal flex flex-col gap-3'>
            <li ><span className=' font-semibold'>Information We Collect</span>
            <br />
            We may collect personal information from you when you visit our website, register an account, place an order, or contact us for support. The types of information we may collect include your name, email address, phone number, billing address, and payment information.
            </li>
            <li><span className=' font-semibold'>Use of Information</span>
            <br />
            We use the information we collect to provide you with our services, process your orders, communicate with you, improve our website, and personalize your experience. We may also use your information to send you promotional offers or newsletters if you have opted to receive them.
            </li>
            <li><span className=' font-semibold'>Disclosure of Information</span>
            <br />
            We may share your personal information with trusted third-party service providers who assist us in operating our website, processing payments, and delivering our services to you. We do not sell, rent, or trade your personal information to third parties for marketing purposes.</li>
            <li><span className=' font-semibold'>Data Security</span>
            <br />
            We take appropriate measures to protect the security of your personal information and ensure it is treated securely by this Privacy Policy. However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee absolute security.</li>

            <li><span className=' font-semibold'>Cookies and Tracking Technologies</span>
            <br />
            We may use cookies and similar tracking technologies to enhance your browsing experience, analyze website traffic, and personalize content. You can adjust your browser settings to refuse cookies, but please note that some features of our website may not function properly.
            </li>

            <li><span className=' font-semibold'>Third-Party Links</span>
            <br />
            Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of any third-party websites you visit.</li>
         
          <li><span className=' font-semibold'>Changes to Privacy Policy</span>
            <br />
            We reserve the right to modify or update this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated policy on our website. It is your responsibility to review this policy periodically to stay informed about our practices.</li>
          
        </ol>

        <p>Please <Link to={"/contact-us"} className=' text-orange-500 underline'>Contact us</Link> if you have any questions or concerns regarding these terms and conditions.</p>
    </div>
    <Footer/>
    </>
  )
}

export default PrivacyPolicy