import React from "react";
import { useNavigate } from "react-router-dom";

const ArticleCard = ({ articleSlug, image, title, description }) => {
  const navigate = useNavigate();
  return (
    <div
      className=" shadow-sm shadow-gray-500  p-3 rounded-xl border-[1px] min-w-[310px] border-gray-200 max-w-[400px] flex flex-col gap-3 min-h-[350px] cursor-pointer"
      onClick={() => navigate(`/puja-articles/${articleSlug}`)}
    >
      <img
        alt={title}
        title={title}
        src={
          image?.startsWith("https")
            ? image
            : require(`../../../../Assets/uploads/${image}`)
        }
        className=" w-full rounded-xl max-h-[168px] object-fill"
        loading="lazy"
      />
      <h2 className=" text-2xl font-bold ">{title}</h2>
      <h2 className=" text-base text-gray-600 ">
        {description?.slice(0, 100)}...
      </h2>

      <button className=" w-full p-2 rounded-xl text-white font-bold cursor-pointer gradientButton relative overflow-hidden">
        <div className=" w-[80px] h-[150px] bg-white -top-2 -left-2 rotate-45 absolute -skew-x-[45deg] opacity-40 animateButton"></div>
        Know more
      </button>
    </div>
  );
};

export default ArticleCard;
