import React, { useEffect } from "react";
import "./App.css";
import Navbar from "./component/common/Navbar";
import { Route, Router, Routes, useLocation } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import MyProfile from "./component/core/Dashboard/Profile/MyProfile";
import Dashboard from "./Pages/Dashboard";
import CreatePooja from "./component/core/Dashboard/Puja/CreatePooja";
import MyPuja from "./component/core/Dashboard/MyPuja/MyPuja";
import CreatePackage from "./component/core/Dashboard/Package/CreatePackage";
import MyPackage from "./component/core/Dashboard/Package/MyPackage";
import CreateItem from "./component/core/Dashboard/Items/CreateItem";
import MyItems from "./component/core/Dashboard/Items/MyItems";
import Setting from "./component/core/Dashboard/Settings/Setting";
import PujaPage from "./Pages/PujaPage";
import PoojaById from "./component/core/PujaSection/PoojaById";
import PaymentPage from "./component/core/PaymentSection/PaymentPage";
import BookedPooja from "./component/core/Dashboard/BookedPooja/BookedPooja";
import EnrolledUser from "./component/core/Dashboard/EnrolledUser/EnrolledUser";
import { PrivateRoute } from "./PrivateRoute";
import CreateLibrary from "./component/core/Dashboard/Library/CreateLibrary";
import LibraryDetailsByName from "./component/core/LibrarySection/LibraryDetailsByName";
import LibraryDetailById from "./component/core/LibrarySection/LibraryDetailById";
import AllLibraryContent from "./component/core/Dashboard/LibraryContent/AllLibraryContent";
import AllArticles from "./component/core/LibrarySection/AllArticles";
import CreateSection from "./component/core/Dashboard/Section/CreateSection";
import Footer from "./component/common/Footer";
import CreateReview from "./component/core/Dashboard/Review/CreateReview";
import TemplePage from "./Pages/TemplePage";
import CreatePrivateCoupon from "./component/core/Dashboard/Coupon/CreateCoupon";
import AllCoupon from "./component/core/Dashboard/Coupon/AllCoupon";
import CreateTempleLoc from "./component/core/Dashboard/TempleLoc/CreateTempleLoc";
import CreateTempleDetails from "./component/core/Dashboard/TempleDetail/CreateTempleDetails";
import TempleById from "./component/core/TempleSection/TempleById";
import Astrology from "./Pages/Astrology";
import GetAstrologyUser from "./component/core/Dashboard/Astrology/GetAstrologyUser";
import ForgotPassword from "./Pages/ForgotPassword";
import UpdateForgotPassword from "./Pages/UpdateForgotPassword";
import ContactUs from "./Pages/ContactUs";
import CreateFaq from "./component/core/Dashboard/Faq/CreateFaq";
import Subs from "./component/core/Dashboard/Subscribers/Subs";
import AddArticle from "./component/core/Dashboard/Article/AddArticle";
import MyArticle from "./component/core/Dashboard/MyArticle/MyArticle";
import PujaArticle from "./Pages/PujaArticle";
import ArticleById from "./component/core/PujaArticleSection/ArticleById/ArticleById";
import OfflinePuja from "./component/core/Dashboard/OfflinePuja/OfflinePuja";
import TermsCondition from "./component/core/FooterPages/TermsCondition";
import PrivacyPolicy from "./component/core/FooterPages/PrivacyPolicy";
import ContactData from "./component/core/Dashboard/Contact/ContactData";
import { ScrollToTop } from "./Utils/ScrollToTop";
import Aboutus from "./Pages/Aboutus";
import EditMarque from "./component/core/Dashboard/Marque/EditMarque";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

const TRACKING_ID = "G-9SNJBQZFKV";
ReactGA.initialize(TRACKING_ID);

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
}

const tagManagerArgs = {
  gtmId: "GTM-WHBR47D9",
};
TagManager.initialize(tagManagerArgs);

const App = () => {
  return (
    // <>
    // <Router>
    <div>
      <Helmet>
        <meta name="theme-color" content="#FFA500" />
      </Helmet>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/puja" element={<PujaPage />} />
        <Route path="/puja/:slug" element={<PoojaById />} />
        <Route path="/puja/:slug/:packageId" element={<PaymentPage />} />
        <Route
          path="/articles/:sectionName"
          element={<LibraryDetailsByName />}
        />
        <Route
          path="/articles/:sectionName/:subsectionId"
          element={<LibraryDetailById />}
        />
        <Route path="/articles" element={<AllArticles />} />
        <Route path="/temples" element={<TemplePage />} />
        <Route path="/temples/:templeId" element={<TempleById />} />
        <Route path="/astrology" element={<Astrology />} />
        <Route path="/update-password" element={<ForgotPassword />} />
        <Route
          path="/update-password/:token"
          element={<UpdateForgotPassword />}
        />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/puja-articles" element={<PujaArticle />} />
        <Route path="/puja-articles/:slug" element={<ArticleById />} />
        <Route path="/terms-and-condition" element={<TermsCondition />} />
        <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />
        <Route path="/aboutUs" element={<Aboutus />} />

        <Route path="/dashboard" element={<Dashboard />}>
          <Route
            path="/dashboard/my-profile"
            element={
              <PrivateRoute>
                <MyProfile />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard/create-puja"
            element={
              <PrivateRoute>
                <CreatePooja />
              </PrivateRoute>
            }
          />

          <Route
            path="/dashboard/my-pooja"
            element={
              <PrivateRoute>
                <MyPuja />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/create-package"
            element={
              <PrivateRoute>
                <CreatePackage />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/my-package"
            element={
              <PrivateRoute>
                <MyPackage />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/offering-items"
            element={
              <PrivateRoute>
                <CreateItem />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/my-items"
            element={
              <PrivateRoute>
                <MyItems />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/settings"
            element={
              <PrivateRoute>
                <Setting />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/booked-pooja"
            element={
              <PrivateRoute>
                <BookedPooja />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/Enrolled-user"
            element={
              <PrivateRoute>
                <EnrolledUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/create-library"
            element={
              <PrivateRoute>
                <CreateLibrary />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/library-content"
            element={
              <PrivateRoute>
                <AllLibraryContent />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/create-section"
            element={
              <PrivateRoute>
                <CreateSection />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/create-review"
            element={
              <PrivateRoute>
                <CreateReview />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/create-Coupon"
            element={
              <PrivateRoute>
                <CreatePrivateCoupon />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/all-Coupon"
            element={
              <PrivateRoute>
                <AllCoupon />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/temple-loc"
            element={
              <PrivateRoute>
                <CreateTempleLoc />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/temple-detail"
            element={
              <PrivateRoute>
                <CreateTempleDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/astrology-user"
            element={
              <PrivateRoute>
                <GetAstrologyUser />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/create-faq"
            element={
              <PrivateRoute>
                <CreateFaq />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/subscribers"
            element={
              <PrivateRoute>
                <Subs />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/add-articles"
            element={
              <PrivateRoute>
                <AddArticle />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/my-articles"
            element={
              <PrivateRoute>
                <MyArticle />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/offline-puja"
            element={
              <PrivateRoute>
                <OfflinePuja />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/contact-data"
            element={
              <PrivateRoute>
                <ContactData />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard/edit-marque"
            element={
              <PrivateRoute>
                <EditMarque />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </div>

    // </Router>
    // </>
  );
};

export default App;
