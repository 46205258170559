import React, { useEffect, useState } from 'react'
import { articleEndPoint } from '../../../../Services/AllApi'
import { apiConnector } from '../../../../Services/ApiConnector'
import { formattedDate } from '../../../../Utils/DateFormatter'

const OfflinePuja = () => {
    const {GET_ALL_OFFLINE_CONTACT_API} = articleEndPoint
    const [Contacts, setContacts] = useState([])
    useEffect(() => {
      const offlineContact = async()=>{
        try {
            const response = await apiConnector("GET", GET_ALL_OFFLINE_CONTACT_API)
            setContacts(response?.data?.data)
            
        } catch (error) {
            console.log(error)
        }
      }
      offlineContact()
    }, [])
    console.log(Contacts)
    
  return (
    <div className='  mt-20 flex gap-10 flex-col items-center justify-center'>
        <h1 className='text-3xl font-bold uppercase text-orange-500'>Offline puja Booked Contacts</h1>
        <div className=' w-full grid gap-9 sm:grid-cols-2 lg:grid-cols-3'>
            {
                Contacts.map((data,index)=>{
                    return <div className=' flex items-center gap-1 flex-col border border-gray-500 rounded-sm'>
                        <p>{data?.fullName}</p>
                        <p>{data?.email}</p>
                        <p>{formattedDate(data?.dob)}</p>
                        <p>{data?.phoneNum}</p>
                        <p>{data?.gotra}</p>
                        <p>{data?.article?.title}</p>
                        <p>{data?.message}</p>
                        <p>{formattedDate(data?.createAt)}</p>
                    </div>
                })
            }
        </div>
    </div>
  )
}

export default OfflinePuja