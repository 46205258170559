import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { apiConnector } from '../../../../Services/ApiConnector'
import { articleEndPoint } from '../../../../Services/AllApi'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageUpload from '../Puja/ImageUpload';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import LibraryImageUpload from '../Library/LibraryImage';

const EditArticleModal = ({articleId, cancelHandler}) => {
    const { register, handleSubmit, formState:{errors}, setValue} = useForm()
    const [Details, setDetails] = useState("")
    const {token} = useSelector((state)=> state.auth)
    const {GET_ARTICLE_BY_ID_API, EDIT_ARTICLE_API} = articleEndPoint
    useEffect(() => {
     const article = async()=>{
        try {
            const result = await apiConnector("POST",GET_ARTICLE_BY_ID_API, {articleId} )
            setDetails(result.data.data)
            
        } catch (error) {
            console.log(error)
        }
     }
     article()
    }, [articleId])

   

    
    
    const onSubmit = async(data)=>{
        const {title, description, image,image2, slug, ques1, ques2, ques3, ques4, ques5, ques6, ans1, ans2, ans3, ans4, ans5, ans6, text} = data
        console.log(data)
        
        const toastId = toast.loading("Please wait")
        try {
            const response = await apiConnector("POST",EDIT_ARTICLE_API , {title, description, text, slug, image,image2, ques1, ques2, ques3, ques4, ques5, ques6, ans1, ans2, ans3, ans4, ans5, ans6, articleId}, {
                "Content-Type": "multipart/form-data",
                      Authorization:`Bearer ${token}`
                  })
            toast.success("Created")
            cancelHandler()
            
        } catch (error) {
            console.log(error)
        }
        toast.dismiss(toastId)
    }
  return (
    <div className='fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm flex items-center justify-center overflow-y-auto '>
      <form action="" className='w-[40%] mx-auto flex flex-col gap-7 items-center mt-[125%] bg-white pb-10 px-4' onSubmit={handleSubmit(onSubmit)}>
                <div>
            <ImageUpload setValue={setValue} register={register} errors={errors} name={"image"} label={"Add image"} />
            <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"image2"} label={"Add image"} />
        </div>
                <div className="flex w-[70%] flex-col gap-2 px-2">
                    <label className="text-orange-500 font-semibold">
                        Enter title<sup className="text-red-500 text-sm">*</sup>
                    </label>
                    <input
                        type="text"
                        className="w-full border-2 border-orange-500 rounded-xl p-2"
                        placeholder="Enter title here"
                        {...register("title", { required: true })}
                        defaultValue={Details?.title}
                    />
                    {errors.title && (
                        <span className="text-red-500">Title is required</span>
                    )}
                </div>
                <div className="flex w-[70%] flex-col gap-2 px-2">
                    <label className="text-orange-500 font-semibold">
                        Enter description<sup className="text-red-500 text-sm">*</sup>
                    </label>
                    <textarea
                        type="text"
                        className="w-full border-2 border-orange-500 rounded-xl p-2"
                        placeholder="Enter description here"
                        {...register("description", { required: true })}
                        defaultValue={Details?.description}

                    />
                    {errors.description && (
                        <span className="text-red-500">Description is required</span>
                    )}
                </div>
                <div className="flex w-[70%] flex-col gap-2 px-2">
                    <label className="text-orange-500 font-semibold">
                        Enter slug<sup className="text-red-500 text-sm">*</sup>
                    </label>
                    <input
                        type="text"
                        className="w-full border-2 border-orange-500 rounded-xl p-2"
                        placeholder="Enter slug here"
                        {...register("slug", { required: true })}
                        defaultValue={Details?.slug}

                    />
                    {errors.slug && (
                        <span className="text-red-500">slug is required</span>
                    )}
                </div>
               
                <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the article<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("text", { required: true })}
      defaultValue={Details?.article}

    />
    {errors.text && (
      <span className=" text-red-500">text is required</span>
    )}
    </div>


    <h1 className=' text-xl lg:text-3xl font-bold uppercase'>Add article faq here</h1>
  
  <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 1<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques1", { required: true })}
      defaultValue={Details?.ques1}

    />
    {errors.ques1 && (
      <span className=" text-red-500">Question 1 is required</span>
    )}
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 1<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 1 here"
      {...register("ans1", { required: true })}
      defaultValue={Details?.ans1}

    />
    {errors.ans1 && (
      <span className=" text-red-500">Solution 1 is required</span>
    )}
    </div>

    <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 2<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques2", { required: true })}
      defaultValue={Details?.ques2}

    />
    {errors.ques2 && (
      <span className=" text-red-500">Question 2 is required</span>
    )}
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 2<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 1 here"
      {...register("ans2", { required: true })}
      defaultValue={Details?.ans2}

    />
    {errors.ans2 && (
      <span className=" text-red-500">Solution 2 is required</span>
    )}
    </div>

    <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 3<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques3", { required: true })}
      defaultValue={Details?.ques3}

    />
    {errors.ques3 && (
      <span className=" text-red-500">Question 3 is required</span>
    )}
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 3<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 1 here"
      {...register("ans3", { required: true })}
      defaultValue={Details?.ans3}

    />
    {errors.ans3 && (
      <span className=" text-red-500">Solution 3 is required</span>
    )}
    </div>

    <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 4<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques4", { required: true })}
      defaultValue={Details?.ques4}

    />
    {errors.ques4 && (
      <span className=" text-red-500">Question 4 is required</span>
    )}
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 4<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 1 here"
      {...register("ans4", { required: true })}
      defaultValue={Details?.ans4}

    />
    {errors.ans4 && (
      <span className=" text-red-500">Solution 4 is required</span>
    )}
    </div>

    <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 5<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques5", { required: false })}
      defaultValue={Details?.ques5}

    />
    
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 5<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 5 here"
      {...register("ans5", { required: false })}
      defaultValue={Details?.ans5}

    />
   
    </div>

    <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 6<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques6", { required: false })}
      defaultValue={Details?.ques6}

    />
   
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 6<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 6 here"
      {...register("ans6", { required: false })}
      defaultValue={Details?.ans6}

    />
    
    </div>

    
   
                <div className="w-full items-center flex justify-center p-2 mt-5">
                    <button className="bg-orange-500 px-5 py-2 rounded-xl hover:bg-orange-600 transition-all duration-200">Submit</button>
                </div>
                <button className=' underline'
        onClick={()=> cancelHandler()}
        >cancel</button>

            </form>
    </div>
  )
}

export default EditArticleModal