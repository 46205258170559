import React from 'react'

const Termscomponent = () => {
  return (
    <div className=' px-3 mt-24'>
    <p style={{whiteSpace:"pre-wrap"}}>
    PLEASE READ THESE TERMS OF USE CAREFULLY. BY USING THE PLATFORM, YOU AGREE TO BE BOUND BY ALL OF THE BELOW TERMS AND CONDITIONS AND THE PRIVACY POLICY.
     <br />


1. INTRODUCTION
<br />

The websites (https://devsthana.com/, and https://devsthana.com/) and the mobile application on Android and iOS Platforms ‘devsthana.com’ (together “Platform”) are owned and operated by devsthana, a msme firm in India under the Companies Act, and includes any of our affiliates, associates, assignees or successors-in-interest as determined by us at our sole discretion and without requiring any prior notice or intimation to you (“Company”, “we” or “us” or “our”). Your (“you”, “your” or “user”) use of the Services (as defined herein) is subject to these terms and conditions (“Terms of Use”).
<br />
<br />

<br />



1.2. Please read these Terms of Use, along with the Privacy Policy available at [https://www.devsthana/privacy-policy/] and all other rules and policies made available or published on the Platform as they shall govern your use of the Platform and the services provided thereunder.
<br />

1.3. By using or visiting the Platform, you signify your agreement to these Terms of Use and the Privacy Policy.
<br />
 
1.4. These Terms of Use are an electronic record as per the Information Technology Act, 2000 (as amended/re-enacted) and rules thereunder (“IT Act") and is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines and Digital Media Ethics code) Rules, 2021, which mandates the publishing of rules and regulations, privacy policy and terms and conditions for access or usage of any application or website. This electronic record is generated by a computer system and does not require any physical or digital signature. In addition, some of the Services may be subject to additional terms and conditions specified by us from time to time; your use of such solution is subject to those additional terms and conditions, which are incorporated into these Terms of Use by reference.
<br />
<br />

2. OVERVIEW OF THE SERVICES
<br />

We are, inter alia, engaged in the business of digitizing temples and other religious experiences for our users, providing astrological services and other related services (“Services”).
<br />
<br />

3. ACCEPTANCE OF TERMS
<br />

By downloading and/or by registering or signing up for these Services, or otherwise having access to, receiving, and/or using the Platform, you acknowledge to have read, understood and consented to be governed and bound by these Terms of Use and the Privacy Policy. If you do not accept or agree to any part of these Terms of Use or the Privacy Policy, your usage of the Services will be terminated.
<br />
<br />

4. ACCESS
<br />

4.1. Subject to these Terms of Use, we may offer to provide you with Services selected by you, solely for your own use, and not for the use or benefit of any third party. Services shall include, but not be limited to, any services we perform for you, any hardware offered by us, or any widgets that you download from the Platform. In case of any descrepancy in the Services, you must bring the same to our notice in writing, within a period of 7 (seven) days from date of performance of the Services, failing which the Services shall be deemed accepted, fulfilled and satisfactorily completed.
<br />

4.2. You agree not to circumvent, disable or otherwise interfere with security-related features of the Platform or features that prevent or restrict use or copying of any restricted information.
<br />
<br />

5. REGISTRATION AND ELIGIBILITY
<br />

5.1. Only persons who can form legally binding contracts under the Indian Contract Act, 1872 may access the Platform and avail our Services. Persons who are ‘incompetent to contract’ within the meaning of the Indian Contract Act, 1872 including without limitation, un-discharged insolvents, are not eligible to avail our Services. If you are a minor i.e., under the age of 18 (eighteen) years, you cannot register and/or avail our Services. We reserve the right to refuse to provide you with access to the Services if it is brought to our notice or if it is discovered that you are incompetent to contract. You represent and warrant to us that you are of legal age to form a binding contract and are not a person barred from availing the Services under applicable laws.
<br />

5.2. You may, at your sole discretion, log into the application using your email/phone number (“Login Details”).
<br />

5.3. Notwithstanding anything contained herein, you shall not:
     <br />

5.3.1 provide any false personal information to us (including false/fraudulent Login Details) or create any account for anyone other than yourself without such person's explicit permission; or
<br />

5.3.2 use the Login Details of another person with the intent to impersonate that person.
<br />
<br />

6. YOUR RESPONSIBILITIES
<br />

6.1. By using the Platform, you represent and warrant that:
<br />

6.1.1. you have fully read and understood the Terms of Use and Privacy Policy and consent to them;
<br />

6.1.2. you will ensure that your use of the Platform and/or Services will not violate any applicable law or regulation;
<br />

6.1.3. you have no rights in, or to, the Platform or the technology used or supported by the Platform or any of the Services, other than the right to use each of them in accordance with these Terms of Use;
<br />

6.1.4. you will not use the Platform or the Services in any manner inconsistent with these Terms of Use or Privacy Policy;
<br />

6.1.5. you will not resell or make any commercial use of the Services or use the Services in any way that is unlawful, for any unlawful purpose, or in a manner that your use harms us, the Platform, or any other person or entity, as determined in our sole discretion, or act fraudulently or maliciously;
<br />

6.1.6. you will not decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any hardware, or source code or underlying ideas or algorithms of any part of the Service (including without limitation any application or widget), except to the limited extent applicable laws specifically prohibit such restriction;
<br />

6.1.7. you will not transmit or make available any software or other computer files that contain a virus or other harmful component, or otherwise impair or damage the Platform or any connected network, or otherwise damage, disable, overburden, impair or compromise the Platform, our systems or security or interfere with any person or entity's use or enjoyment of the Platform;
<br />

6.1.8. you will not post, publish or transmit any content or messages that (i) are false, misleading, defamatory, harmful, threatening, abusive or constitute harassment (ii) promote racism, entail hateful slurs or promote hateful behavior, associate with hate groups or any violence towards others including terrorism or self-harm or suicide or harm against any individual or group or religion or caste, (iii) infringe another's rights including any intellectual property rights or copyright or trademark, violate or encourage any conduct that would violate any applicable law or regulation or would give rise to civil liability, or (iv) depict or encourage profanity, nudity, inappropriate clothing, sexual acts, sexually suggestive poses or degrade or objectify people, whether in the nature of a prank, entertainment or otherwise.
<br />

6.1.9. you will not promote the use of explosives or firearms, the consumption of psychotropic drugs or any other illegal activities;
<br />

6.1.10. you will not disparage, make false or malicious statements against us or in connection with the Services or the Platform;
<br />

6.1.11. you will not interfere or attempt to interfere with the proper working of the Platform or any activities conducted on the Platform;
<br />

6.1.12. you will not bypass any measures we may use to prevent or restrict access to the Services;
<br />

6.1.13. you will not run any form of autoresponder or “spam” on the Platform;
<br />

6.1.14. you will not use manual or automated software, devices, or other processes to “crawl” or “spider” any part of the Services;
<br />

6.1.15. you will not modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, republish, repurpose, sell, trade, or in any way exploit the Service, except as expressly authorized by us;
<br />

6.1.16. you will not delete or modify any content of the Services, including but not limited to, legal notices, disclaimers or proprietary notices such as copyright or trademark symbols, logos, that you do not own or have express permission to modify
<br />
<br />

7. PAYMENT TERMS
<br />

You hereby acknowledge and agree that in order to avail our astrology services, you explicitly agree to pay the fees in a manner and mode, as notified to you by us. The said fee is subject to a periodic review and may be revised at our sole discretion and such revisions may be intimated to you as and when required. Please note that your network operator may also separately charge you for data and other usage.
<br />
<br />

8. CANCELLATION AND REFUND POLICY
<br />

8.1. You may request to cancel your order up to 24 (twenty four) hours before the date and time of the event. For cancellation you may call us at +91 78801 18870  or send us an email to info@devsthana.com up to 24 (twenty four) hours before the date and time of the event. The money will be refunded to your original source of payment within 7-10 (seven to ten) business days.
<br />

8.2. With respect to astrology services, refund may be considered in the following instances only:
<br />

8.2.1. Network issue due to which the chat/call was affected during the service or prevalence of factors such as weak signal, background noise or inaudibility of the astrologer during the session.
<br />

8.2.2. Astrologer was unable to respond fluently in the language provided in their profile
<br />

8.2.3. Astrologer was taking an inordinately long time to respond to the user.
     <br />

8.2.4. Astrologer has responded with irrelevant or inappropriate responses to the user’s query.
     <br />

8.3. No refund shall be processed in the following instances
     <br />

8.3.1. The order has reached the processing stage i.e., an astrologer has already been assigned.
     <br />

8.3.2. The Platform suffers a technical delay or glitch in processing the request or generating the report from the astrologer.
     <br />

8.3.3. The information provided by you is incorrect. You agree to verify any information submitted to the Platform and ensure that any such information is accurate. In the event the information provided is incorrect, you may request to rectify the information, provided such request is made within 1 (one) hour from the service rendered by the astrologer.
     <br />

8.3.4. The contact number provided by you for the purpose of availing the astrology services is incorrect. Once you choose to avail a call with the astrologer, you are requested to ensure that your contact number is reachable, within coverage area and that you answer such call when received.
     <br />

8.3.5. Inaccurate results from the consultation. Please note that we do not take responsibility for factual accuracy of the results arising out the service rendered.
     <br />

8.4. The refunds, if any, shall be processed after deduction of the transaction charges levied by the bank and/or the payment gateway and/or any other charges that may have been incurred by the Platform during processing and/or delivering the service, as applicable.
<br />
<br />

9. CONDITIONS TO USE
<br />

9.1. The Services will be provided on a best-efforts basis. We will make reasonable efforts and shall endeavor that you are able to use the Services without undue disruption, interruption or delay
<br />

9.2. Once you login on the Platform, you may receive updates, promotional materials and other information we may send with regards to the Service, or new services we may offer. You hereby consent to receiving all such commercial communications from us. You may opt out of receiving any, or all, of these commercial communications by writing to us at legal@devsthana.com.
<br />

By agreeing to these Terms of Use, you also hereby unconditionally consent to us arranging a call with you on your mobile number although such number may be availing the DND service provided by your mobile service provider.
<br />

9.3. We disclaim any responsibility for any harm resulting from anyone’s use of or access to the Services. If you avail our Services, you are responsible for taking precautions as necessary to protect yourself and your device (s) from malware, viruses, spyware, trojan horses, worms or trap doors, and other such harmful or destructive software.
<br />

9.4. We disclaim any responsibility for any harm resulting from anyone’s use of or access to the Services. If you avail our Services, you are responsible for taking precautions as necessary to protect yourself and your device (s) from malware, viruses, spyware, trojan horses, worms or trap doors, and other such harmful or destructive software. You also agree that we shall not be liable to you for any damages that may result from your use and/or misuse of our Platform.
<br />
<br />

10. INTELLECTUAL PROPERTY
<br />

10.1. You agree and acknowledge that we are and we will remain the owner of the Platform and the Services thereunder at all times. You acknowledge that copyright in works contained on the Platform and the Services, including but not limited to all the features, functionality software, design, text, sound recordings and images, are our exclusive property, or licensed by us, except as otherwise expressly stated. You may access the Platform as a bona fide visitor or only for your use of the Services offered.
<br />

10.2. All trademarks, service marks, trade names, trade dress, and other forms of intellectual property are proprietary to us. No information, code, algorithms, content or material from the Platform or the Services may be copied, reproduced, republished, uploaded, posted, transmitted or distributed in any way without our express written permission.
<br />
<br />

11. THIRD-PARTY LINKS
<br />

11.1. The Platform includes links to third-party websites and/or applications. You acknowledge that when you access a third-party link that leaves the Platform:
<br />

11.1.1. the website or application you enter into is not controlled by the Company and different terms of use and privacy policies may apply;
<br />

11.1.2. the inclusion of a link does not imply any endorsement by the Company of the third-party website and/or application, the website’s and/or application’s provider, or the information on the third-party website and/or application; and
<br />

11.1.3. if you submit any information or details on any of those websites and/or applications, such information is governed by the terms of use and privacy policies of such third-party websites and/or applications and the Company disclaims all responsibility or liability with respect to these terms of use, policies or the websites and/or applications
<br />

11.2. You are encouraged to carefully read the terms of use and privacy policy of any third-party website and/or application that you visit. The Company reserves the right to disable third-party links from the Platform, although the Company is under no obligation to do so.
<br />
<br />

12. INDEMNITY AND LIMITATION OF LIABILITY
<br />

12.1. To the extent permitted by law, you agree to indemnify the Company, its officers, directors and employees from and against any losses, damages, costs, expenses and claims arising out of (i) your use of the Services; (ii) any breach of these Terms of Use or Privacy Policy by you; (iii) any infringement of any intellectual property or other rights of the Company or any third- party or (iv) your breach of any applicable laws.
<br />


12.2. To the fullest extent permitted by law, in no event will the Company or its affiliates be liable in respect of the Platform and/or the Services for any direct, indirect, special, incidental, punitive, exemplary or consequential damages whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not the Company has been warned of the possibility of such damages. You expressly understand that under no circumstances, including negligence, will the Company be liable to you or any other person or entity for any direct, indirect, incidental, special, remote or consequential damages, including, but not limited to damages for loss of profits, goodwill, use, data or other intangible losses, resulting from circumstances, including with respect to:
<br />

12.2.1 the use or the inability to use the Platform and/or the Services;
<br />

12.2.2 your reliance on the statements or claims made by us in the course of rendering our Services; or
<br />

12.2.3 any other matter relating to the Platform and/or Services.
<br />
12.3. Additionally, by using the Platform or any of the Services, you acknowledge and agree that internet transmissions are never completely private or secure. You understand that any message or information you send using the Platform or any of the Services may be read or intercepted by others, even if there is a special notice that a particular transmission is encrypted.
     <br />
<br />

13. TERMINATION
<br />

13.1. We may terminate your access to all or any part of the Service at any time, with or without cause, with or without notice, effective immediately. Any suspected illegal, fraudulent or abusive activity will also be grounds for terminating your access to the Platform and/or Services.
<br />

13.2. We reserve the right to, at our sole discretion, (a) cease operating the Platform or any of the Services at any time without notice, and/or (b) terminate these Terms of Use.
<br />

13.3. All provisions of these Terms of Use which by their nature survive termination shall survive termination, including, without limitation, intellectual property (Clause 10), indemnity and limitation of liability (Clause 12) and disclaimer (Clause 14).
<br />
<br />

14. DISCLAIMER
<br />

14.1. We hereby expressly state that the Services provided on the Platform are solely in relation to the Sanatana Dharma. You hereby agree and acknowledge that such restriction of Services to the Sanatana Dharma is in no manner discriminatory towards other religions or religious practices and shall not, under any circumstances whatsoever be deemed to be a disrespect of other religions or be deemed as favouring of Sanatana Dharma over other religions or religious practices.
<br />

14.2. We do not in any manner represent or warrant nor do we undertake any responsibility or liability about the reality or reliability of the astrological effects on human physiology or any other products or services represented and sold on the Platform. No advice or information, whether oral or written, obtained by you through the Platform while availing the Services (including from any third-party service provider) shall create any warranty by the Company. We do not encourage or tolerate any content that promotes actions involving black magic, witchcraft, voodoo or tantrism in any manner. We do not commit to treating or providing solutions for users experiencing weak physical and/or mental health nor do we provide any medical advice. Users are advised to seek appropriate medical advice for any issues relating to physical or mental health.
<br />


14.3. The advisors/consultants/astrologers are not employees of the Platform or the Company and are third party service providers. You agree and acknowledge that you are connecting with third party service providers at your own risk, and we undertake no responsibility or liability with respect to such third-party service providers. We do not refer, endorse, recommend, verify, evaluate or guarantee any advice, information or other services provided by the third-party service providers or by the Company, nor do we warrant the validity, accuracy, completeness, safety, legality, quality, or applicability of the content, anything said or written by, or any advice provided by such third-party service providers. You further agree that in no event will we be made a party to or have any liability with respect to any dispute between you and any third-party service provider. We may terminate the services of any third-party service provider at any time and without any liability, at our sole discretion.
<br />


14.4. Save to the extent required by law, we have no special relationship with or fiduciary duty to you. You acknowledge that we have no control over, and no duty to take any action regarding the effects the Services may have on you.
<br />

14.5. You agree and acknowledge that the Services are provided on an “as is” basis, and that we hereby do not guarantee or warrant to the accuracy, adequacy, correctness, validity, completeness, or suitability for any purpose, of the Services and accept no liability or responsibility with respect to your reliance on the statements or claims made by us in the course of rendering our Services.
<br />

14.6. The Company makes no representation or warranty that the content on the Platform is appropriate to be used or accessed outside the Republic of India. Any users who use or access the Platform or avail the Services from outside the Republic of India, do so at their own risk and are responsible for compliance with the laws of such jurisdiction.
<br />
<br />

15. GOVERNING LAW
<br />

15.1. A printed version of these Terms of Use and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms of Use to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.
<br />

15.2. These Terms of Use shall be governed by and construed in accordance with the laws of India. For all purposes of these Terms of Use, you consent to the exclusive jurisdiction of the courts located in Bangalore, India. Use of the Service is not authorized in any jurisdiction that does not give effect to all provisions of these Terms of Use, including without limitation, this section.
<br />
<br />

16. SEVERABILITY
<br />

If any provision of these Terms of Use is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms of Use will otherwise remain in full force and effect and enforceable. The failure of either party to exercise, in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. Waiver of compliance in any particular instance does not mean that we will waive compliance in the future. In order for any waiver of compliance with these Terms of Use to be binding, we must provide you with written notice of such waiver through one of our authorized representatives.
<br />
<br />

17. MODIFICATION OF TERMS OF USE
<br />

We reserve the right, at our sole discretion, to modify or replace any of these Terms of Use, or change, suspend, or discontinue the Services (including without limitation, the availability of any feature, database, or content) or its usage at any time by posting a notice or by sending you notice through our Service or via email/contact details provided as Login Details. We may also impose limits on certain features and services or restrict your access to parts or all of the Services without notice or liability. It is your responsibility to check these Terms of Use periodically for changes. Your continued use of the Services following the posting of any changes to these Terms of Use shall constitute an acceptance of those changes.
<br />
<br />

18. MISCELLANEOUS
<br />

18.1. Unless otherwise specified in these Terms of Use, all notices hereunder will be in writing and will be deemed to have been duly given when received or when receipt is electronically confirmed, if transmitted by e-mail.
<br />

18.2. In respect of these Terms of Use and your use of these Services, nothing in these Terms of Use shall be deemed to grant any rights or benefits to any person, other than us and you, or entitle any third party to enforce any provision hereof, and it is agreed that we do not intend that any provision of these Terms of Use should be enforceable by a third party as per any applicable law.
<br />

    </p>

    </div>
  )
}

export default Termscomponent