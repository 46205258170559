import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { pujaEndPoints } from '../../../../Services/AllApi'
import { apiConnector } from '../../../../Services/ApiConnector'
import toast from 'react-hot-toast'
import ImageUpload from '../Puja/ImageUpload'
import LibraryImageUpload from '../Library/LibraryImage'

const EditDateModal = ({poojaId, token, cancelHandler}) => {

    const { register, handleSubmit, formState:{errors}, setValue} = useForm()
    const {EDIT_DATE_API, GET_PUJA_DETAILS_BY_ID_API}= pujaEndPoints
    const [details, setdetails] = useState()

    const onSubmit = async(data)=>{
        const {title, description, address, date, templeDetail, benefit1,
          benefit2,
          benefit3,
          benefit4,
          benefit5,
          benefit6 ,
          benefitDesc1,
          benefitDesc2,
          benefitDesc3,
          benefitDesc4,
          benefitDesc5,
          benefitDesc6, slug, image1, image2, image3, image4, poojaPackage,templeName,benefitImage1, benefitImage2, benefitImage3, benefitImage4, benefitImage5, benefitImage6 } = data
          
        const toastId = toast.loading("Please wait")
        try {
            await apiConnector("PUT", EDIT_DATE_API, {poojaId, title, description, address, date, templeDetail, benefit1,
              benefit2,
              benefit3,
              benefit4,
              benefit5,
              benefit6 ,
              benefitDesc1,
              benefitDesc2,
              benefitDesc3,
              benefitDesc4,
              benefitDesc5,
              benefitDesc6, slug, image1, image2, image3, image4, poojaPackage,templeName,benefitImage1, benefitImage2, benefitImage3, benefitImage4, benefitImage5, benefitImage6 }, {
              "Content-Type": "multipart/form-data",
                Authorization:`Bearer ${token}`
            })
            toast.success("Updated successfully")
            cancelHandler()
        } catch (error) {
            console.log(error)
        }
        toast.dismiss(toastId)
    }

    useEffect(() => {
     const getPooja = async()=>{
      try {
        const response = await apiConnector("POST", GET_PUJA_DETAILS_BY_ID_API, {poojaId})
        setdetails(response?.data?.data)
        if(response){
          setValue("title", response?.data?.data?.title)
    setValue("description", response?.data?.data?.description)
    setValue("slug", response?.data?.data?.slug)
    setValue("address", response?.data?.data?.address)
    setValue("templeName", response?.data?.data?.templeName)
    setValue("templeDetail", response?.data?.data?.templeDetail)
    setValue("benefit1", response?.data?.data?.benefit1)
    setValue("benefit2", response?.data?.data?.benefit2)
    setValue("benefit3", response?.data?.data?.benefit3)
    setValue("benefit4", response?.data?.data?.benefit4)
    setValue("benefit5", response?.data?.data?.benefit5)
    setValue("benefit6", response?.data?.data?.benefit6)
    setValue("benefitDesc1", response?.data?.data?.benefitDesc1)
    setValue("benefitDesc2", response?.data?.data?.benefitDesc2)
    setValue("benefitDesc3", response?.data?.data?.benefitDesc3)
    setValue("benefitDesc4", response?.data?.data?.benefitDesc4)
    setValue("benefitDesc5", response?.data?.data?.benefitDesc5)
    setValue("benefitDesc6", response?.data?.data?.benefitDesc6)

        }
       
        
      } catch (error) {
        console.log(error)
      }
     }
     getPooja()
    }, [poojaId])


    
    


    

  return (
    <div className=' fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm flex items-center justify-center overflow-y-auto '>
        <form className=' p-3 flex flex-col items-center justify-center bg-white gap-5 rounded-xl  lg:mt-[315%] mt-[530%] mb-20 lg:w-[30%] w-[90%]' onSubmit={handleSubmit(onSubmit)}>
        <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter title<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter title here"
            {...register("title", { required: false })}
          />
         
        </div>


        <div className=" flex flex-col gap-2 px-2n w-full">
          <label className=" text-orange-500 font-semibold">
            Enter about pooja<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2 min-h-40"
            placeholder="Enter about pooja here"
            {...register("description", { required: false })}
          />
        
        </div>

        <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter slug for puja<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter slug here"
            {...register("slug", { required: false })}
          />
          
        </div>


        <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter address<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2 min-h-24"
            placeholder="Enter address here"
            {...register("address", { required: false })}
          />
        
        </div>
          
        <div className=" flex flex-col gap-2 px-2">
          <label className=" text-orange-500 font-semibold">
            Enter date<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="datetime-local"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter date of pooja start "
            {...register("date", { required: false })}
          />
        </div>

       

        <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter temple name<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter temple name "
            {...register("templeName", { required: false })}
          />
         
        </div>


        <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter temple detail<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2 min-h-[200px]"
            placeholder="Enter temple name "
            {...register("templeDetail", { required: false })}
          />
        
        </div>

        <div>
        <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"image1"} label={"Add image 1"} />
          <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"image2"} label={"Add image 2"} />
          <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"image3"} label={"Add image 3"} />
           <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"image4"} label={"Add image 4"} />
        </div>

        <div className=' w-full flex items-center justify-center flex-col gap-4'>
        <div>
            <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"benefitImage1"} label={"Add benfit icon"} />
        </div>
        <div className=" flex flex-col gap-2 px-2 w-full" >
          <label className=" text-orange-500 font-semibold">
            Enter benfit title 1<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter title here"
            {...register("benefit1", { required: false })}
          />
          
          </div>
          <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter benefit description<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter description here"
            defaultValue={details?.benefitDesc1}
            {...register("benefitDesc1", { required: false })}
          />
        
          </div>
          <div className=" w-full items-center flex justify-center p-2">
        </div>
    </div>

    <div className=' w-full flex items-center justify-center flex-col gap-4'>
        <div>
            <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"benefitImage2"} label={"Add benfit icon"} />
        </div>
        <div className=" flex flex-col gap-2 px-2 w-full" >
          <label className=" text-orange-500 font-semibold">
            Enter benfit title 2<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter title here"
            {...register("benefit2", { required: false })}
          />
          
          </div>
          <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter benefit description<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter description here"
            {...register("benefitDesc2", { required: false })}
          />
        
          </div>
          <div className=" w-full items-center flex justify-center p-2">
        </div>
    </div>

    <div className=' w-full flex items-center justify-center flex-col gap-4'>
        <div>
            <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"benefitImage3"} label={"Add benfit icon"} />
        </div>
        <div className=" flex flex-col gap-2 px-2 w-full" >
          <label className=" text-orange-500 font-semibold">
            Enter benfit title 3<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter title here"
            defaultValue={details?.benefit3}
            {...register("benefit3", { required: false })}
          />
          
          </div>
          <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter benefit description<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter description here"
            defaultValue={details?.benefitDesc3}
            {...register("benefitDesc3", { required: false })}
          />
        
          </div>
          <div className=" w-full items-center flex justify-center p-2">
        </div>
    </div>

    <div className=' w-full flex items-center justify-center flex-col gap-4'>
        <div>
            <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"benefitImage4"} label={"Add benfit icon"} />
        </div>
        <div className=" flex flex-col gap-2 px-2 w-full" >
          <label className=" text-orange-500 font-semibold">
            Enter benfit title 4<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter title here"
            {...register("benefit4", { required: false })}
          />
          </div>
          <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter benefit description<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter description here"
            {...register("benefitDesc4", { required: false })}
          />
        
          </div>
          <div className=" w-full items-center flex justify-center p-2">
        </div>
    </div>
    
    <div className=' w-full flex items-center justify-center flex-col gap-4'>
        <div>
            <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"benefitImage5"} label={"Add benfit icon"} />
        </div>
        <div className=" flex flex-col gap-2 px-2 w-full" >
          <label className=" text-orange-500 font-semibold">
            Enter benfit title 5<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter title here"
            {...register("benefit5", { required: false })}
          />
          </div>
          <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter benefit description<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter description here"
            {...register("benefitDesc5", { required: false })}
          />
        
          </div>
          <div className=" w-full items-center flex justify-center p-2">
        </div>
    </div>

    <div className=' w-full flex items-center justify-center flex-col gap-4'>
        <div>
            <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"benefitImage6"} label={"Add benfit icon"} />
        </div>
        <div className=" flex flex-col gap-2 px-2 w-full" >
          <label className=" text-orange-500 font-semibold">
            Enter benfit title 6<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter title here"
            {...register("benefit6", { required: false })}
          />
          </div>
          <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter benefit description<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter description here"
            {...register("benefitDesc6", { required: false })}
          />
        
          </div>
          <div className=" w-full items-center flex justify-center p-2">
        </div>
    </div>


      <div className=' flex flex-col gap-3 items-center' >
      <button className=' p-3 bg-orange-500'>Submit</button>
        <button className=' underline'
        onClick={()=> cancelHandler()}
        >cancel</button>
      </div>
      </form>
    </div>
  )
}

export default EditDateModal