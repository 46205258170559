import React, { useEffect, useState } from 'react'
import { authEndPoints } from '../../../../Services/AllApi'
import { apiConnector } from '../../../../Services/ApiConnector'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

const EditMarque = () => {
    const {EDIT_MARQUE_API, GET_MARQUE_API} = authEndPoints
    const {register,  handleSubmit, formState:{errors}}= useForm()
    const [detail, setdetail] = useState("")
    useEffect(() => {
        const getMarque = async()=>{
            try {
                const response = await apiConnector("GET", GET_MARQUE_API)
                setdetail(response.data.data[0])
            } catch (error) {
                console.log(error)
            }
        }
        getMarque()
    }, [])

    const onSubmit= async(data)=>{
        const {text} = data
        const marqueId = detail._id
        try {
            await apiConnector("POST", EDIT_MARQUE_API, {text, marqueId})
            toast.success("Updated successfully")
        } catch (error) {
            console.log(error)
        }
     }
    
  return (
    <div className=' flex flex-col items-center justify-center gap-10 lg:px-0 px-2'>
    <h1 className=' text-3xl font-bold text-orange-500'>Edit marque</h1>
   <form className=' w-full lg:w-[40%] flex flex-col gap-3 p-3' onSubmit={handleSubmit(onSubmit)}>
   <div className=" flex flex-col gap-2 px-2" >
          <label className=" text-orange-500 font-semibold">
            Enter marque<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter title here"
            {...register("text", { required: true })}
            defaultValue={detail?.text}
          />
          {errors.text && (
            <span className=" text-red-500">text is required</span>
          )}
          </div>
          <button className=' p-3 bg-orange-500'>Submit</button>
   </form>
    </div>
  )
}

export default EditMarque