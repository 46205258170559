import { ACCOUNT_TYPE } from "../Utils/Constants";

export const DASHBOARD_LINK = [
    {
        id:1,
        title:"My Profile",
        path:"/dashboard/my-profile",
        icon: "VscAccount",
    },
    {
        id:2,
        title:"Create puja",
        path:"/dashboard/create-puja",
        accountType: ACCOUNT_TYPE.ADMIN
    },{
        id:3,
        title:"Create package",
        path:"/dashboard/create-package",
        accountType: ACCOUNT_TYPE.ADMIN

    },
    {
        id:8,
        title:"My package",
        path:"/dashboard/my-package",
        accountType: ACCOUNT_TYPE.ADMIN

    },
    {
        id:4,
        title:"My pooja",
        path:"/dashboard/my-pooja",
        accountType: ACCOUNT_TYPE.ADMIN

    },
    {
        id:6,
        title:"Offering items",
    path:"/dashboard/offering-items",
        accountType: ACCOUNT_TYPE.ADMIN
    },
    {
        id:9,
        title:"My items",
    path:"/dashboard/my-items",
        accountType: ACCOUNT_TYPE.ADMIN
    },
    {
        id:12,
        title:"Create library",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/create-library",
    }, 
    {
        id:13,
        title:"Library content",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/library-content",
    }, 
    {
        id:14,
        title:"Create section",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/create-section",
    },
    {
        id:15,
        title:"Create review",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/create-review",
    },
    // {
    //     id:16,
    //     title:"Create coupon",
    //     accountType: ACCOUNT_TYPE.ADMIN,
    // path:"/dashboard/create-Coupon",
    // },
    // {
    //     id:17,
    //     title:"All coupon",
    //     accountType: ACCOUNT_TYPE.ADMIN,
    // path:"/dashboard/all-Coupon",
    // },
    {
        id:16,
        title:"Create FAQ",
        accountType: ACCOUNT_TYPE.ADMIN,
        path:"/dashboard/create-faq",
    },
    {
        id:18,
        title:"Create temple loc",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/temple-loc",
    },
    {
        id:19,
        title:"Create temple details",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/temple-detail",
    },
    {
        id:7,
        title:"Enrolled user",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/Enrolled-user",
    },
    {
        id:20,
        title:"Astrology user",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/astrology-user",
    },
    {
        id:21,
        title:"Subscribers",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/subscribers",
    },
    {
        id:22,
        title:"Add articles",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/add-articles",
    },
     {
        id:23,
        title:"My articles",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/my-articles",
    },
   
    {
        id:24,
        title:"Offline puja booked",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/offline-puja",
    },
    {
        id:25,
        title:"Contact data",
        accountType: ACCOUNT_TYPE.ADMIN,
    path:"/dashboard/contact-data",
    },
    
    {
        id:26,
        title:"Edit marques",
        accountType: ACCOUNT_TYPE.ADMIN,
        path:"/dashboard/edit-marque",
    }, 
    {
        id:26,
        title:"Booked puja",
        accountType: ACCOUNT_TYPE.USER,
        path:"/dashboard/booked-pooja",
    },
    

]