import React, { useState } from 'react'
import { useEffect } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { apiConnector } from '../../../Services/ApiConnector'
import { pujaEndPoints } from '../../../Services/AllApi'

const FAQS = () => {
    const [show, setshow] = useState(false)
    const [faqs, setfaqs] = useState([])
    const {GET_ALL_FAQ_API}= pujaEndPoints

    useEffect(() => {
      const getFaqs = async()=>{
        try {
          const response = await apiConnector("GET",GET_ALL_FAQ_API)
          setfaqs(response.data.data)
          
        } catch (error) {
          console.log(error)
        }
      }
      getFaqs()
    }, [])
    
  return (
   <>
    
     {
      faqs.map((data)=>{
        return  <details className=' w-full flex flex-col justify-between text-gray-500'>
   
   <summary className={`${show?" text-black":" text-gray-500"} w-full flex flex-col items-center cursor-pointer  gap-5 text-xl py-3`} onClick={()=> setshow(!show)}>
   <div className=' w-full flex items-center justify-between'>
   <p>{data.ques}</p>

{
             show? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />
         }
   </div>
   <div className=' w-full h-[1px] bg-gray-300'></div>
   </summary>
            
    <p>{data.ans}</p>
            
       
    </details>
      })
    
     }
   </>
  )
}

export default FAQS