import React from 'react'
import frontImage1 from "../../../Assets/home animation/image1.jpg"
import frontImage2 from "../../../Assets/home animation/image2.jpg"
import frontImage3 from "../../../Assets/home animation/image3.jpg"
import frontImage4 from "../../../Assets/home animation/image4.jpg"
import frontImage5 from "../../../Assets/home animation/image5.jpg"
import devsthanaFlower from "../../../Assets/home animation/homeatsro.jpg"

const Animatedround = () => {
  return (
    // <div className=" lg:w-[100%] w-[100%]  h-fit mt-32 flex items-center justify-center overflow-x-hidden">
    <div className=" w-[100%] flex items-center justify-center lg:pt-9 pt-8 bgImage relative  ">
    <img src={devsthanaFlower} alt="devsthana homepage" title='devsthana homepage' className=' lg:min-w-[500px] min-w-[300px] h-[300px] lg:h-[500px] object-cover rounded-full  absolute' />
      <div className=" w-fit h-fit relative z-10 ">
        <img
          src={frontImage1}
          alt="mahakal"
          title='mahakal'
          className=" lg:w-[250px] w-[100px] h-[100px] lg:h-[110px] object-cover rounded-full mx-auto "
        />
      </div>
      <div className=" w-[200px] h-[200px] lg:w-[300px] lg:h-[300px] rounded-full border-2 absolute animate-spin ">
        <img
          src={frontImage2}
          alt="vishnu g"
          title='vishnu g'
          className=" lg:w-[80px] lg:h-[80px] w-[50px] h-[50px] rounded-full object-cover bottom-7 lg:bottom-6 absolute  animate-spin2"
        />
        <img
          src={frontImage3}
          alt="krishna g"
          title='krishna g'
          className=" lg:w-[80px] lg:h-[80px] w-[50px] h-[50px] object-cover rounded-full top-4 -right-2 lg:right-0  absolute animate-spin2"
        />
      </div>

      <div className="w-[300px] h-[300px] lg:w-[500px] lg:h-[500px] rounded-full border-2  absolute  animate-spin ">
        <img
          src={frontImage4}
          alt="ram g"
          title='ram g'
          className=" lg:w-[80px] lg:h-[80px] w-[60px] h-[60px]  rounded-full top-0 left-4 lg:left-24  absolute  animate-spin2 "
        />
        <img
          src={frontImage5}
          alt="hanuman g"
          title='hanuman g'

          className="lg:w-[100px] lg:h-[100px] w-[60px] h-[60px]  rounded-full bottom-3 lg:bottom-11 right-0  object-cover absolute animate-spin2"
        />
    </div>
  </div>
// </div>
  )
}

export default Animatedround