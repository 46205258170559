import React from 'react'

const Benefitsection = ({icons, title, description}) => {
  return (
    <div
    className=" w-[300px] max-h-[200px] flex  gap-3 rounded-xl p-3"
  >
    <img
      src={icons}
      alt=""
      className=" w-[40px] h-[40px] object-cover rounded-full "
    />
    <div className=" flex items-start gap-2 flex-col">
      <h2 className=" text-BLACK-90 text-[18px] font-bold">{title}</h2>
      <p className=" text-md leading-[20.5px] text-gray-500">
        {
        description
        }
        
      </p>
     
    </div>
  </div>

  )
}

export default Benefitsection