import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { packageEnPoints } from '../../../../Services/AllApi'
import { apiConnector } from '../../../../Services/ApiConnector'
import toast from 'react-hot-toast'

const PackageModal = ({packageId, cancelHandler}) => {
    const { register, handleSubmit, formState:{errors}, setValue} = useForm()
    const [Details, setDetails] = useState("")
    const {GET_PACKAGE_BY_ID_API, EDIT_PACKAGE_API} = packageEnPoints
    useEffect(() => {
     const getDetails = async()=>{
        try {
            const response = await apiConnector("POST", GET_PACKAGE_BY_ID_API, {packageId})
            setDetails(response.data.data)
        } catch (error) {
            console.log(error)
        }
     }
     getDetails()
    }, [])
    
    const onSubmit = async(data)=>{
        const { price, title, addPeople,benefit1,benefit2,benefit3, benefit4 } = data;
        const toastid = toast.loading("Please wait..");
        try {
          const response = await apiConnector(
            "POST",
            EDIT_PACKAGE_API,
            { price, title, addPeople, benefit1, benefit2, benefit3,benefit4, packageId },
           
          );
          toast.success("Package updated");
          cancelHandler()
        } catch (error) {
          console.log(error);
        }
        toast.dismiss(toastid);
    }
  return (
   <>
     <div className='fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm flex items-center justify-center overflow-y-auto '>
      <form action="" className='w-[30%] mx-auto flex flex-col gap-7 items-center mt-[25%] py-10 bg-white pb-10 px-4' onSubmit={handleSubmit(onSubmit)}>
      <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter title<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter title here"
            {...register("title", { required: true })}
            defaultValue={Details?.title}
          />
          {errors.title && (
            <span className=" text-red-500">Title is required</span>
          )}
        </div>

        <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            How many people<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="number"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter people here"
            {...register("addPeople", { required: true })}
            defaultValue={Details?.addPeople}

          />
          {errors.addPeople && (
            <span className=" text-red-500">Add People is required</span>
          )}
        </div>

        <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter price<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <input
            type="number"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter price here"
            {...register("price", { required: true })}
            defaultValue={Details?.price}

          />
          {errors.price && (
            <span className=" text-red-500">Price is required</span>
          )}
        </div>  

        <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter benefit1 <sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter benefit 1 here"
            {...register("benefit1", { required: true })}
            defaultValue={Details?.benefit1}

          />
          {errors.benefit1 && (
            <span className=" text-red-500">Benefit1 is required</span>
          )}
        </div> 

        <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter benefit 2<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter benefit 2 here"
            {...register("benefit2", { required: true })}
            defaultValue={Details?.benefit2}

          />
          {errors.benefit2 && (
            <span className=" text-red-500">benefit2 is required</span>
          )}
        </div> 


        <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter benefit3<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter benefit3 here"
            {...register("benefit3", { required: true })}
            defaultValue={Details?.benefit3}

          />
          {errors.benefit3 && (
            <span className=" text-red-500">benefit3 is required</span>
          )}
        </div>


        <div className=" flex flex-col gap-2 px-2 w-full">
          <label className=" text-orange-500 font-semibold">
            Enter benefit 4<sup className=" text-red-500 text-sm">*</sup>
          </label>
          <textarea
            type="text"
            className=" w-full border-2 border-orange-500  rounded-xl p-2"
            placeholder="Enter benefit 4 here"
            {...register("benefit4", { required: false })}
            defaultValue={Details?.benefit4}

          />
        
        </div>
        <div className=" w-full items-center flex justify-center p-2">
          <button className=" bg-orange-500 px-5 py-2 rounded-xl hover:bg-orange-600 transition-all duration-200">
            Submit
          </button>
        </div>
        <button className=' underline'
        onClick={()=> cancelHandler()}
        >cancel</button>
      </form>
    </div>
   
   </>
  )
}

export default PackageModal