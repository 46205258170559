import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sectionEndPoint } from "../../../Services/AllApi";
import { apiConnector } from "../../../Services/ApiConnector";
import LibraryNavbar from "./LibraryNavbar";
import starImage from "../../../Assets/stars.png";
import Footer from "../../common/Footer";
import { Helmet } from "react-helmet-async";
import TextAnimation from "../Homecomponent/TextAnimation";

const LibraryDetailsByName = () => {
  const { sectionName } = useParams();
  const { GET_SECTION_BY_NAME_API } = sectionEndPoint;
  const [loading, setloading] = useState(false);
  const [sectionDetail, setsectionDetail] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const sectionDetail = async () => {
      setloading(true);
      try {
        const response = await apiConnector("POST", GET_SECTION_BY_NAME_API, {
          sectionName,
        });
        setsectionDetail(response.data.data);
      } catch (error) {
        console.log(error);
      }
      setloading(false);
    };

    sectionDetail();
  }, [sectionName]);

  return (
    <>
      <div className=" mt-32">
        <LibraryNavbar sectionTitle={sectionName} />
        {loading ? (
          <div className=" w-full h-full flex items-center justify-center">
            <span className="loader"></span>
          </div>
        ) : (
          <div className=" flex flex-col gap-20 lg:mt-32 max-w-screen-xl mx-auto pb-20 lg:px-0 px-3  ">
  
            <div className=" relative">
              <img
                src={sectionDetail.image}
                alt={sectionDetail?.mainHindiTitle}
                title={sectionDetail?.mainHindiTitle}
                className=" w-full rounded-xl object-cover max-h-[430px]"
              />
              <p className=" absolute left-3 text-2xl lg:text-6xl font-bold text-white bottom-3">
                {sectionDetail?.mainHindiTitle}
              </p>
            </div>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4  gap-9 place-items-center flex-wrap ">
              {sectionDetail?.subsection?.map((data, index) => {
                console.log(data);
                return (
                  <div
                    className=" lg:w-[170px] w-full max-h-[250px] flex lg:flex-col flex-row items-center lg:gap-1 gap-1 cursor-pointer "
                    key={index}
                    onClick={() =>
                      navigate(`/articles/${sectionName}/${data.slug}`)
                    }
                  >
                    <img
                      alt={data?.title1}
                      title={data?.title1}
                      className=" w-[170px] object-fill min-h-[200px] rounded-xl"
                      src={
                        data?.image1?.startsWith("https")
                          ? data?.image1
                          : require(`../../../Assets/uploads/${data?.image1}`)
                      }
                    />

                    <div className=" flex flex-col gap-2">
                      <p className=" font-bold  text-[17px]">{data.title1}</p>
                      <p className=" font-bold text-gray-500 text-[14px]">
                        {data.description1}
                      </p>
                      <img
                        src={starImage}
                        alt={data.title1}
                        title={data.title1}
                        className=" w-[90px] -mt-4"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <TextAnimation />

      <Footer />
    </>
  );
};

export default LibraryDetailsByName;
