import React, { useEffect, useState } from "react";

const Timer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = targetDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div>
      {days !== undefined ? (
        <div className=" flex items-center gap-1">
          <div className="  flex bg-orange-100 flex-row  items-end lg:items-end lg:p-2 p-1 rounded-xl gap-2 lg:gap-3">
            <p className=" text-lg lg:text-2xl font-semibold text-orange-500 ">
              {days}
            </p>
            <p className="text-orange-500 lg:text-base text-sm">Days</p>
          </div>{" "}
        
          <div className=" flex bg-orange-100 flex-row  items-end lg:items-end lg:p-2 p-1 rounded-xl gap-2 lg:gap-3">
            <p className="  text-lg lg:text-2xl font-semibold text-orange-500 ">
              {hours}
            </p>
            <p className="text-orange-500 lg:text-base text-sm">Hours</p>
          </div>
          <div className=" flex bg-orange-100 flex-row  items-end lg:items-end lg:p-2 p-1 rounded-xl gap-2 lg:gap-3">
            <p className="  text-xl  lg:text-2xl font-semibold text-orange-500">
              {minutes}
            </p>
            <p className="text-orange-500 lg:text-base text-sm">Minutes</p>
          </div>
          <div className="flex bg-orange-100 flex-row  items-end lg:items-end lg:p-2 p-1 rounded-xl gap-2 lg:gap-3">
            <p className=" text-lg lg:text-2xl font-semibold text-orange-500">
              {seconds}
            </p>
            <p className="text-orange-500 lg:text-base text-sm">Seconds</p>
          </div>
        </div>
      ) : (
        <div className=" flex items-center gap-2 ">
          <div className="  flex bg-orange-100 flex-row  items-end lg:items-end lg:p-2 p-1 rounded-xl gap-2 lg:gap-3">
            <p className=" text-lg lg:text-2xl font-semibold text-orange-400  animate-pulse">
              0
            </p>
            <p className="text-orange-500 lg:text-base text-sm">Days</p>
          </div>{" "}
          <div className="flex bg-orange-100 flex-row  items-end lg:items-end lg:p-2 p-1 rounded-xl gap-2 lg:gap-3">
            <p className="  text-lg lg:text-2xl font-semibold text-orange-400  animate-pulse">
              0
            </p>
            <p className="text-orange-500 lg:text-base text-sm">Hours</p>
          </div>
          <div className=" flex bg-orange-100 flex-row  items-end lg:items-end lg:p-2 p-1 rounded-xl gap-2 lg:gap-3">
            <p className="  text-lg lg:text-2xl font-semibold text-orange-400 animate-pulse">
              0
            </p>
            <p className="text-orange-500 lg:text-base text-sm">Minutes</p>
          </div>{" "}
          <div className=" flex bg-orange-100 flex-row  items-end lg:items-end lg:p-2 p-1 rounded-xl gap-2 lg:gap-3">
            <p className=" text-lg lg:text-2xl font-semibold text-orange-400 animate-pulse">
              0
            </p>
            <p className="text-orange-500 lg:text-base text-sm">Seconds</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Timer;
