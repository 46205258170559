import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './Reducers/Index';
import { Toaster } from 'react-hot-toast';
import {HelmetProvider} from "react-helmet-async"

const store = configureStore({
    reducer:rootReducer
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<HelmetProvider>

<Provider store={store}>

<BrowserRouter>
  <App />
  <Toaster/>
</BrowserRouter>
</Provider>
</HelmetProvider>

  
);
