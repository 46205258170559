import React from 'react'
import { FcOk } from "react-icons/fc";
import { FcNext } from "react-icons/fc";

const PaymentNavbar = () => {
  return (
    <div className=' w-full bg-green-100 py-4 mt-16'>
        <div className=' max-w-screen-xl mx-auto flex items-center lg:gap-4 gap-1 text-[12px] px-2'>
        <div className=' flex items-center gap-2'>
        <FcOk />
        <p className=' text-green-500'>Add Details</p>
        </div>
        <p className=' flex items-center gap-0'>  <span><FcNext /></span><span><FcNext className=' -ml-2' /></span></p>
        <p className=' text-blue-600 flex items-center gap-1'>
        <span className=' text-white px-[4px]  text-[10px] rounded-full bg-blue-500'>2</span>
            Review Booking
        </p>
        <p className=' flex items-center gap-0'>  <span><FcNext /></span><span><FcNext className=' -ml-2' /></span></p>

        <p className=' flex items-center gap-1'>
        <span className=' text-white px-[4px]  text-[10px] rounded-full bg-green-500'>3</span>

            Make Payment
        </p>
        </div>
    </div>
  )
}

export default PaymentNavbar