import React from 'react'
import KalsarpPujaPanditPankajGuruji from "../../../Assets/pujaris/Kalsarp Puja Pandit Pankaj Guruji.jpeg"
import PanditPurshotamGaur from "../../../Assets/pujaris/Pandit Purshotam Gaur (Sharma).jpeg"
import aacharyajigneshpandy from "../../../Assets/pujaris/aacharya jignesh pandy.jpg"
import VishwanathShastri from "../../../Assets/pujaris/Vishwanath Shastri.jpeg"
import Aacharyasachhidanandsankritya from "../../../Assets/pujaris/Aacharya sachhidanand sankritya.jpeg"
import PanditVijayJoshiUjjain from "../../../Assets/pujaris/Pandit Vijay Joshi Ujjain.jpeg"
import panditkailashnarayansharma from "../../../Assets/pujaris/pandit kailash narayan sharma.jpeg"
import astroarunpanditji from "../../../Assets/pujaris/astro arun pandit ji.jpeg"
import PtNityanandShastri from "../../../Assets/pujaris/Pt. Nityanand Shastri.jpeg"
import AcharyaRajeshvaranandJiMaharaj from "../../../Assets/pujaris/Acharya Rajeshvaranand Ji Maharaj.jpeg"

const Pujaris = () => {
  return (
    <div className='w-full mt-20 max-w-screen-xl'>
        <div className='w-full   flex items-start gap-5 flex-col'>
        <h2 className='lg:text-3xl text-2xl font-bold bg-gradient-to-r from-orange-600 to-red-500 text-transparent bg-clip-text '>Meet the experienced community of Devsthana Purohit's</h2>
        <p className=' text-xl  text-gray-500'>Our committment is to perform puja with true devotion towards the divine and in accordance with Vedic scriptures. We perform pujas at shaktipeeth, jyotirlinga and sacred temples to ensure you and your family gets glessings of well-being and auspiciousness in your life.</p>
        </div>
        <div className='md:flex-row grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-7 gap-6 place-items-center'>
           <div className=' relative max-h-[400px]'>
           <img src={KalsarpPujaPanditPankajGuruji} alt="Kalsarp Puja Pandit Pankaj Guruji" title='Kalsarp Puja Pandit Pankaj Guruji' className=' rounded-xl max-h-[350px] min-h-[350px] w-[300px] object-cover' />
           <p className=' absolute bottom-3 left-5 text-lg text-black text-center font-semibold'>Kalsarp Puja Pandit Pankaj Guruji</p>
           </div>

           <div className=' relative max-h-[400px]'>
           <img src={PanditPurshotamGaur} alt="Pandit Purshotam Gaur (Sharma)" title='Pandit Purshotam Gaur (Sharma)' className=' rounded-xl h-full max-h-[350px] min-h-[350px] w-[300px] object-cover' />
           <p className=' absolute bottom-3 left-5 text-lg text-black text-center font-semibold'>Pandit Purshotam Gaur (Sharma)</p>
           </div>


           <div className=' relative max-h-[400px]'>
           <img src={aacharyajigneshpandy} alt="Aacharya jignesh pandy" title='Aacharya jignesh pandy' className=' rounded-xl h-full max-h-[350px] min-h-[350px]  w-[300px]  object-cover' />
           <p className=' absolute bottom-3 left-5 text-lg text-black text-center font-semibold'>Aacharya jignesh pandy</p>
           </div>

          <div className=' relative max-h-[400px]'>
           <img src={VishwanathShastri} alt="Vishwanath Shastri" title='Vishwanath Shastri' className=' rounded-xl h-full max-h-[350px] min-h-[350px]  w-[300px]  object-cover' />
           <p className=' absolute bottom-3 left-9 text-lg text-black text-center font-semibold'>Vishwanath Shastri</p>
           </div>

          <div className=' relative max-h-[400px]'>
           <img src={Aacharyasachhidanandsankritya} alt="Aacharya sachhidanand sankritya" title='Aacharya sachhidanand sankritya' className=' rounded-xl h-full max-h-[350px]  min-h-[350px] w-[300px]  object-cover' />
           <p className=' absolute bottom-3 left-5 text-lg text-black text-center font-semibold'>Aacharya sachhidanand sankritya</p>
           </div>
 <div className=' relative max-h-[400px]'>
           <img src={PanditVijayJoshiUjjain} alt="Pandit Vijay Joshi Ujjain" title='Pandit Vijay Joshi Ujjain' className=' rounded-xl h-full min-h-[350px] max-h-[350px]  w-[300px]  object-cover' />
           <p className=' absolute bottom-3 left-9 text-lg text-black text-center font-semibold'>Pandit Vijay Joshi Ujjain</p>
           </div>

      <div className=' relative max-h-[400px]'>
           <img src={panditkailashnarayansharma} alt="pandit kailash narayan sharma" title='pandit kailash narayan sharma' className=' rounded-xl h-full min-h-[350px] max-h-[350px]  w-[300px]  object-cover' />
           <p className=' absolute bottom-3 left-9 text-lg text-black text-center font-semibold'>pandit kailash narayan sharma</p>
           </div>

      <div className=' relative max-h-[400px]'>
           <img src={astroarunpanditji} alt="pandit kailash narayan sharma" title='pandit kailash narayan sharma' className=' rounded-xl h-full min-h-[350px] max-h-[350px]  w-[300px]  object-cover' />
           <p className=' absolute bottom-3 left-9 text-lg text-black text-center font-semibold'>Astro arun pandit ji</p>
           </div>

     
      <div className=' relative max-h-[400px]'>
           <img src={PtNityanandShastri} alt="pandit kailash narayan sharma" title='pandit kailash narayan sharma' className=' rounded-xl h-full min-h-[350px] max-h-[350px]  w-[300px]  object-cover' />
           <p className=' absolute bottom-3 left-9 text-lg text-black text-center font-semibold'>Pt. Nityanand Shastri</p>
           </div>

      <div className=' relative max-h-[400px]'>
           <img src={AcharyaRajeshvaranandJiMaharaj} alt="pandit kailash narayan sharma" title='pandit kailash narayan sharma' className=' rounded-xl h-full min-h-[350px] max-h-[350px]  w-[300px]  object-cover' />
           <p className=' absolute bottom-3 left-9 text-lg text-black text-center font-semibold'>Acharya Rajeshvaranand Ji Maharaj</p>
           </div>

     
        </div>
    </div>
  )
}

export default Pujaris