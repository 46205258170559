import React, { useEffect, useState } from 'react'
import ArticleNavbar from '../component/core/PujaArticleSection/PujaPage/ArticleNavbar'
import { articleEndPoint } from '../Services/AllApi'
import { apiConnector } from '../Services/ApiConnector'
import ArticleCard from '../component/core/PujaArticleSection/PujaPage/ArticleCard'
import articleBg from "../Assets/articleBg.jpg"
import Footer from '../component/common/Footer'
import GetRating from '../component/common/GetRating'
import { Helmet } from 'react-helmet-async'
import { FaSearch } from "react-icons/fa";

const PujaArticle = () => {
    const {GET_ALL_ARTICLE_API, SEARCH_ALL_ARTICLE_API} = articleEndPoint
    const [details, setdetails] = useState([])
    const [key, setkey] = useState("")
    useEffect(() => {
     const getArticle = async()=>{
        try {
            const response = await apiConnector("GET", GET_ALL_ARTICLE_API)
            setdetails(response.data.data)
            
        } catch (error) {
            console.log(error)
        }
     }
     getArticle()
    }, [])

    useEffect(() => {
    const serachArticle = async()=>{
        try {
            const response = await apiConnector("POST", SEARCH_ALL_ARTICLE_API, {key})
            setdetails(response?.data?.data)
            
        } catch (error) {
            console.log(error)
        }
    }
    serachArticle()
    }, [key])
    
    
  return (
   <>

<Helmet>
<title>Devsthana - Articles</title>
<meta name="description" content="Explore a treasure trove of spiritual wisdom with Devsthana's collection of articles. Dive deep into various aspects of spirituality, rituals, and traditions to enrich your spiritual journey. From insightful teachings to practical guidance, discover all your devotional needs in one place. Visit Devsthana.com to immerse yourself in the richness of spiritual knowledge and elevate your connection with the divine."/>
<link rel='canonical' href='/articles' />
<meta property="og:title" content="Devsthana - Puja-Articles" />
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.devsthana.com/puja-articles" />

    </Helmet>
     <div className=' w-full pb-7'>
    <ArticleNavbar/>
    <div className=' relative w-full mx-auto flex items-center justify-center flex-col lg:pt-0 pt-10'>
       <img src={articleBg} alt="article" className=' max-h-[400px] w-full object-cover opacity-75' />
        <div className=' flex items-center flex-col gap-4 absolute'>
        <p className=' lg:text-5xl text-2xl font-semibold text-red-700 uppercase'>Search puja and articles</p>
       <div className=' relative'>
       <input type="search" onChange={(e)=> setkey(e.target.value)} value={key} className=' px-2 py-2 rounded-lg lg:w-[400px] w-[250px] border-2 border-red-600 text-black bg-transparent' placeholder='Search here...' />
        <FaSearch className=' absolute text-white right-3 top-3 placeholder:text-white' />
       </div>
        </div>
    </div>
    <div className=' max-w-screen-xl mx-auto mt-10  grid gap-9 sm:grid-cols-2 lg:grid-cols-3 bg-white px-2 overflow-x-hidden  '>
        {
           details.length > 0 ? details.map((data, index)=>{
                return <ArticleCard key={index} image={data?.image} title={data?.title} description={data?.description} articleSlug={data?.slug} />
            }) : <p className=' h-full text-center'>No article found</p>
        }
    </div>
    </div>
    <div className=' w-full mt-20 max-w-screen-xl mx-auto px-2'>
            <div className=' w-full   flex items-start gap-5 flex-col'>
              <h2 className=' text-3xl font-bold'>What devotees Say about Devsthana Puja ?</h2>
              <p className=' text-xl  text-gray-500'>Reviews and Ratings from our customers who performed  Puja with us.</p>
            </div>
            <div className=' w-full'>
              <GetRating/>
            </div>

          </div>
    <Footer/>
   </>
  )
}

export default PujaArticle