import React from "react";
import { useForm } from "react-hook-form";
import { astrologyEndPoint } from "../Services/AllApi";
import toast from "react-hot-toast";
import { apiConnector } from "../Services/ApiConnector";
import astrologyImage from "../Assets/astrologyFront.jpg";
import Footer from "../component/common/Footer";
import MyTextEditor from "../component/common/Quill";
import { Helmet } from "react-helmet-async";
import TextAnimation from "../component/core/Homecomponent/TextAnimation";

const Astrology = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { CREATE_FORM_API } = astrologyEndPoint;
  const onSubmit = async (data) => {
    console.log(data)
    const { fullName, address, phoneNum, dob, timeOfBirth, placeOfBirth } =
      data;
    const toastid = toast.loading("Please wait..");
    try {
      await apiConnector("POST", CREATE_FORM_API, {
        fullName,
        address,
        phoneNum,
        dob,
        timeOfBirth,
        placeOfBirth,
      });
      toast.success("Thank you! Our pandit ji will contact you shortly.");
      setValue("fullName", " ");
      setValue("address", " ");
      setValue("phoneNum", " ");
      setValue("dob", " ");
      setValue("timeOfBirth", " ");
      setValue("placeOfBirth", " ");
    } catch (error) {
      console.log(error);
    }
    toast.dismiss(toastid);
  };
  return (
    <>
     <Helmet>
     <title>Devsthana - Astrology</title>
<meta name="description" content="Unlock the mysteries of the cosmos with Devsthana's astrology services. Our expert astrologers provide personalized insights and guidance based on ancient wisdom and celestial alignments. Whether seeking clarity on relationships, career paths, or life events, discover the profound insights that astrology can offer. Explore our range of astrology services and embark on a journey of self-discovery and enlightenment with Devsthana."/>
<link rel='canonical' href='/astrology' />
<meta property="og:title" content="Devsthana - Astrology" />
<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.devsthana.com/astrology" />

    </Helmet>
      <div className=" flex items-center justify-between flex-col min-h-screen mt-20 lg:px-0 px-3">
      <div className="w-full -mt-2">
      <TextAnimation/>

      </div>
        <div className=" max-w-screen-xl mx-auto flex flex-col h-full  ">
          <div className=" flex justify-between w-full items-center h-full lg:flex-row flex-col">
            <p className=" lg:text-2xl text-xl text-center font-bold px-5">
              Astrology is the study of the movements and relative positions of
              celestial objects as a means of divining information about human
              affairs and terrestrial events. Please fill out the form below to
              get personalized astrology insights.
            </p>

            <img
              src={astrologyImage}
              alt="astrology"
              className=" rounded-full lg:w-full h-full object-cover animate-spin w-[300px] "
            />
          </div>

          <div className=" w-full flex flex-col items-center justify-center mt-14">
            <form
              className=" w-full lg:w-[40%] flex flex-col gap-3 p-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className=" flex flex-col gap-2 px-2">
                <label className=" text-orange-500 font-semibold">
                  Enter Full name<sup className=" text-red-500 text-sm">*</sup>
                </label>
                <input
                  type="text"
                  className=" w-full border-2 border-orange-500  rounded-xl p-2"
                  placeholder="Enter full name here"
                  {...register("fullName", { required: true })}
                />
                {errors.fullName && (
                  <span className=" text-red-500">fullName is required</span>
                )}
              </div>
              <div className=" flex flex-col gap-2 px-2">
                <label className=" text-orange-500 font-semibold">
                  Enter phone number
                  <sup className=" text-red-500 text-sm">*</sup>
                </label>
                <input
                  type="number"
                  placeholder="Whatsapp Number"
                  className=" w-full border-2 border-orange-500  rounded-xl p-2"
                  {...register("phoneNum", {
                    required: {
                      value: true,
                      message: "Please enter Phone Number",
                    },
                    maxLength: { value: 10, message: "Invalid Phone Number" },
                    minLength: { value: 8, message: "Invalid Phone Number" },
                  })}
                />
                {errors.phoneNum && (
                  <span className=" text-red-500">phoneNum is required</span>
                )}
              </div>
              <div className=" flex flex-col gap-2 px-2">
                <label className=" text-orange-500 font-semibold">
                  Enter address{" "}
                  <sup className=" text-red-500 text-sm">(optional)</sup>
                </label>
                <input
                  type="text"
                  className=" w-full border-2 border-orange-500  rounded-xl p-2"
                  placeholder="Enter address here"
                  {...register("address", { required: false })}
                />
              </div>
              <div className=" flex flex-col gap-2 px-2">
                <label className=" text-orange-500 font-semibold">
                  Enter Date of birth{" "}
                  <sup className=" text-red-500 text-sm">(optional)</sup>
                </label>
                <input
                  type="date"
                  className=" w-full border-2 border-orange-500  rounded-xl p-2"
                  {...register("dob", { required: false })}
                />
              </div>
              <div className=" flex flex-col gap-2 px-2">
                <label className=" text-orange-500 font-semibold">
                  Enter place of birth{" "}
                  <sup className=" text-red-500 text-sm">(optional)</sup>
                </label>
                <input
                  type="text"
                  className=" w-full border-2 border-orange-500  rounded-xl p-2"
                  {...register("placeOfBirth", { required: false })}
                  placeholder="Enter place of birth here"
                />
              </div>
              <div className=" flex flex-col gap-2 px-2">
                <label className=" text-orange-500 font-semibold">
                  Enter Time of birth{" "}
                  <sup className=" text-red-500 text-sm">(optional)</sup>
                </label>
                <input
                  type="time"
                  className=" w-full border-2 border-orange-500  rounded-xl p-2"
                  {...register("timeOfBirth", { required: false })}
                />
              </div>
              <div className=" w-full items-center flex justify-center p-2">
                <button className=" bg-orange-500 px-5 py-2 rounded-xl hover:scale-105  bg-gradient-to-r from-orange-500 to-red-500 text-white transition-all duration-200">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Astrology;
