import React from 'react'
import aboutImage from "../Assets/about.jpg"
import ganeshG from "../Assets/ganeshagabout.jpg"
import pandit from "../Assets/pandit.jpg"
import familyPuja from "../Assets/familyPuja.webp"
import Footer from '../component/common/Footer'

const Aboutus = () => {
  return (
    <div className='mt-18 flex flex-col gap-3'>
        <div className=' w-screeen'>
            <img src={aboutImage} alt="about Image" className=' w-full max-h-[400px] object-cover' />
        </div>
        <div  className=' max-w-screen-xl mx-auto flex items-center flex-col gap-5 px-2'>
            <div className=' flex flex-col items-center'>
                <h1 className=' lg:text-6xl text-2xl'>About us</h1>
                <p>We delve into the rich tapestry of spirituality and embark on a transformative path towards inner awakening.</p>
            </div>


            <div className=' flex items-center lg:flex-row flex-col gap-6'>
            <div className=' w-[50%]'>
                <img src={ganeshG} alt="ganesh g" className=' w-full object-cover' />
            </div>
                <div className=' flex-1'>
                    <h2 className=' text-2xl text-gray-500'>Embark on a Spiritual Odyssey</h2>
                    <p>At Book puja seva, we are dedicated to revealing the profound wisdom and spiritual essence that lies within ancient traditions. With a deep-rooted understanding of sacred rituals and practices, we strive to connect individuals with the divine through our broad range of puja services, temple visits, and spiritual experiences.</p>
                </div>
            </div>

            <div className=' flex items-center lg:flex-row flex-col-reverse gap-6'>
           
                <div className=' flex-1'>
                    <h2 className=' text-2xl text-gray-500'>Sacred Rituals Led by Expert Pandits</h2>
                    <p>Our team of experienced pandits brings years of knowledge and expertise to each puja and ritual, ensuring that every aspect is conducted with precision and authenticity. With their deep understanding of Vedic traditions and meticulous attention to detail, our pandits create an atmosphere of reverence and spirituality, making your puja experience truly meaningful and impactful.</p>
                </div>
                <div className=' w-[50%]'>
                <img src={pandit} alt="pandit g" className=' w-full object-cover max-h-[550px]' />
            </div>
            </div>

            <div className=' flex items-center lg:flex-row flex-col gap-6'>
            <div className=' w-[50%]'>
                <img src={familyPuja} alt="pandit g" className=' w-full object-cover max-h-[400px]' />
            </div>
                <div className=' flex-1'>
                    <h2 className=' text-2xl text-gray-500'>Divine Vedic Pujas</h2>
                    <p>Experience the divine presence and embrace the ancient Vedic traditions with our meticulously performed Vedic Pujas. Our skilled pandits invoke the blessings of the deities through sacred rituals, offering you a profound spiritual experience. Immerse yourself in the timeless wisdom of the Vedas and discover the transformative power of our Vedic Pujas.</p>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Aboutus