import React, { useState } from 'react'
import { RxCross2 } from "react-icons/rx";
import { apiConnector } from '../../Services/ApiConnector';
import { authEndPoints } from '../../Services/AllApi';
import toast from 'react-hot-toast';


const EmailSubsribe = ({cancel}) => {
    const [email, setemail] = useState("")
    const {CREATE_EMAIL_SUBS_API} = authEndPoints
    const handleSubs = async(e)=>{
        e.preventDefault()
        const toastId = toast.loading("Please wait...")
        try {
            const response = await apiConnector("POST", CREATE_EMAIL_SUBS_API, {email})
            cancel()
            toast.success("Thank you for subscribing! We will update you via email.")
        } catch (error) {
            console.log(error)
        }
        toast.dismiss(toastId)
    }
  return (
    <div className=' fixed top-0 left-0 w-screen h-screen inset-0 text-white bg-black bg-opacity-20 backdrop-blur-sm   flex items-center justify-center'>
        <div className=' w-[500px] bg-white text-black flex flex-col items-center p-4 gap-3 '>
        <RxCross2 onClick={cancel} className=' cursor-pointer hover:scale-105' />
        <p className=' text-3xl font-semibold italic text-orange-500'>Subscribe for puja updates</p>
        <p className=' text-center'>Subscribe your email and you will receive emails for new poojas and offers.</p>
            <form action="" className=' w-full flex flex-col gap-5' onSubmit={handleSubs}>
            <input type="text" placeholder='Enter your email here' className=' px-2 p-2 border border-black w-full' value={email} onChange={(e)=> setemail(e.target.value)} />
            <button className=" w-full p-2 rounded-xl text-white font-bold cursor-pointer gradientButton relative overflow-hidden">
      <div className=" w-[80px] h-[150px] bg-white -top-2 -left-2 rotate-45 absolute -skew-x-[45deg] opacity-40 animateButton"></div>
      Subscribe</button>
            </form>
        </div>
    </div>
  )
}

export default EmailSubsribe