import React from 'react'

const PoojaGradientCard = ({number, title, gradientColor, textColor}) => {
  return (
    <div className={`${gradientColor} h-[170px] w-[170px] items-center justify-center flex flex-col gap-2 rounded-full text-center hover:scale-105 transition-all duration-200`}>
        <p className={`${textColor} text-2xl font-bold`}>{number}</p>
        <p className={`${textColor}`}>{title}</p>
    </div>
  )
}

export default PoojaGradientCard