import { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useForm } from 'react-hook-form';
import ImageUpload from '../Puja/ImageUpload';
import { apiConnector } from '../../../../Services/ApiConnector';
import { articleEndPoint } from '../../../../Services/AllApi';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import LibraryImageUpload from '../Library/LibraryImage';

const AddArticle = () => {
    
    const {register, handleSubmit,setValue, formState:{errors}} = useForm();
    const [text, setText] = useState('');
    const {CREATE_ARTICLE_API} = articleEndPoint
    const {token} = useSelector((state)=> state.auth)

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'link'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['clean']
        ],
    };

    const formats = [
        'header', 'bold', 'italic', 'underline', 'link',
        'list', 'bullet'
    ];

    const handleEditorChange = (content, delta, source, editor) => {
        setText(content);
    };

    const onSubmit = async(data) => {
        const {title, description, image, slug, ques1, ques2, aues3, ques4, ques5, ques6, ans1, ans2, ans3, ans4, ans5, ans6, image2} = data
        
        const toastId = toast.loading("Please wait")
        try {
            const response = await apiConnector("POST", CREATE_ARTICLE_API, {title, description, text, slug, image, image2, ques1, ques2, aues3, ques4, ques5, ques6, ans1, ans2, ans3, ans4, ans5, ans6}, {
                "Content-Type": "multipart/form-data",
                      Authorization:`Bearer ${token}`
                  })
            toast.success("Created")
            
        } catch (error) {
            console.log(error)
        }
        toast.dismiss(toastId)
    }

    return (
        <div className='w-full'>
            <form action="" className='w-[70%] mx-auto flex flex-col gap-7 items-center' onSubmit={handleSubmit(onSubmit)}>
                <div>
            <ImageUpload setValue={setValue} register={register} errors={errors} name={"image"} label={"Add image"} />
            <LibraryImageUpload setValue={setValue} register={register} errors={errors} name={"image2"} label={"Add image"} />
        </div>
                <div className="flex w-[70%] flex-col gap-2 px-2">
                    <label className="text-orange-500 font-semibold">
                        Enter title<sup className="text-red-500 text-sm">*</sup>
                    </label>
                    <input
                        type="text"
                        className="w-full border-2 border-orange-500 rounded-xl p-2"
                        placeholder="Enter title here"
                        {...register("title", { required: true })}
                    />
                    {errors.title && (
                        <span className="text-red-500">Title is required</span>
                    )}
                </div>
                <div className="flex w-[70%] flex-col gap-2 px-2">
                    <label className="text-orange-500 font-semibold">
                        Enter description<sup className="text-red-500 text-sm">*</sup>
                    </label>
                    <textarea
                        type="text"
                        className="w-full border-2 border-orange-500 rounded-xl p-2"
                        placeholder="Enter description here"
                        {...register("description", { required: true })}
                    />
                    {errors.description && (
                        <span className="text-red-500">Description is required</span>
                    )}
                </div>
                <div className="flex w-[70%] flex-col gap-2 px-2">
                    <label className="text-orange-500 font-semibold">
                        Enter slug<sup className="text-red-500 text-sm">*</sup>
                    </label>
                    <input
                        type="text"
                        className="w-full border-2 border-orange-500 rounded-xl p-2"
                        placeholder="Enter slug here"
                        {...register("slug", { required: true })}
                    />
                    {errors.slug && (
                        <span className="text-red-500">slug is required</span>
                    )}
                </div>
                <ReactQuill
                    value={text}
                    onChange={handleEditorChange}
                    modules={modules}
                    formats={formats}
                    placeholder="Write something..."
                    className='hx-44 text-xl w-full'
                />


    <h1 className=' text-xl lg:text-3xl font-bold uppercase'>Add article faq here</h1>
  
  <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 1<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques1", { required: true })}
    />
    {errors.ques1 && (
      <span className=" text-red-500">Question 1 is required</span>
    )}
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 1<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 1 here"
      {...register("ans1", { required: true })}
    />
    {errors.ans1 && (
      <span className=" text-red-500">Solution 1 is required</span>
    )}
    </div>

    <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 2<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques2", { required: true })}
    />
    {errors.ques2 && (
      <span className=" text-red-500">Question 2 is required</span>
    )}
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 2<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 1 here"
      {...register("ans2", { required: true })}
    />
    {errors.ans2 && (
      <span className=" text-red-500">Solution 2 is required</span>
    )}
    </div>

    <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 3<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques3", { required: true })}
    />
    {errors.ques3 && (
      <span className=" text-red-500">Question 3 is required</span>
    )}
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 3<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 1 here"
      {...register("ans3", { required: true })}
    />
    {errors.ans3 && (
      <span className=" text-red-500">Solution 3 is required</span>
    )}
    </div>

    <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 4<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques4", { required: true })}
    />
    {errors.ques4 && (
      <span className=" text-red-500">Question 4 is required</span>
    )}
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 4<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 1 here"
      {...register("ans4", { required: true })}
    />
    {errors.ans4 && (
      <span className=" text-red-500">Solution 4 is required</span>
    )}
    </div>

    <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 5<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques5", { required: false })}
    />
    
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 5<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 5 here"
      {...register("ans5", { required: false })}
    />
   
    </div>

    <div className=" flex flex-col gap-2 px-2 w-full" >
    <label className=" text-orange-500 font-semibold">
      Enter the question 6<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <input
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter question here"
      {...register("ques6", { required: false })}
    />
   
    </div> 

    <div className=" flex flex-col gap-2 px-2 w-full">
    <label className=" text-orange-500 font-semibold">
      Enter the solution 6<sup className=" text-red-500 text-sm">*</sup>
    </label>
    <textarea
      type="text"
      className=" w-full border-2 border-orange-500  rounded-xl p-2"
      placeholder="Enter solution 6 here"
      {...register("ans6", { required: false })}
    />
    
    </div>

    
   
                <div className="w-full items-center flex justify-center p-2 mt-5">
                    <button className="bg-orange-500 px-5 py-2 rounded-xl hover:bg-orange-600 transition-all duration-200">Next</button>
                </div>

            </form>
        </div>
    );
}

export default AddArticle;
