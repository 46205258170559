import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'

const ArticleByIdNavbar = ({title}) => {
  return (
    <div
    className=' fixed top-16 z-[200] bg-pink-100 border-t text-base text-gray-700 border-gray-500 w-full left-0 lg:block hidden'
    >
    <div className=' max-w-screen-xl mx-auto p-2 flex flex-row gap-3 items-center'>
        <Link to={"/"} className=' hover:text-orange-500'>Home</Link>
        <IoIosArrowForward className=' text-orange-500 font-bold' />
        <Link to={"/puja-articles"} className=' hover:text-orange-500'>Puja Articles</Link>
        <IoIosArrowForward className=' text-orange-500 font-bold' />
        <Link  className=' hover:text-orange-500'>{title}</Link>

    </div>
    </div>
  )
}

export default ArticleByIdNavbar