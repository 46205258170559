import React, { useEffect, useState } from 'react'
import { apiConnector } from '../../../Services/ApiConnector'
import { authEndPoints } from '../../../Services/AllApi'

const TextAnimation = () => {
  const {GET_MARQUE_API} = authEndPoints
  const [detail, setdetail] = useState("")
  useEffect(() => {
      const getMarque = async()=>{
          try {
              const response = await apiConnector("GET", GET_MARQUE_API)
              setdetail(response.data.data[0])
          } catch (error) {
              console.log(error)
          }
      }
      getMarque()
  }, [])
  return (
    <div className=' w-full overflow-hidden bg-gradient-to-r lg:text-base text-sm from-red-600 to-orange-600 p-2'>
            <div className='infinite-animation'>
            {detail.text}
            </div>
    </div>
  )
}

export default TextAnimation