import React from 'react'
import {motion} from "framer-motion"
import { Link } from 'react-router-dom'

const GradientCard = ({gradient, icon, title, text, textcolor ,link}) => {
  return (
    <motion.div initial={{x:100, opacity:0}} whileInView={{x:0, opacity:100}} transition={{duration:1}}   viewport={{ once: false }} className={` ${gradient } p-6 rounded  block  space-y-3`}>
           <Link to={link}>
           <img src={icon} alt={title} title={title} className=' rounded-full w-[60px] h-[60px]' />
            <p className={`${textcolor} mb-2 text-xl font-bold text-18 leading-19`}>{title}</p>
            <p className=' text-gray-500  text-lg font-medium leading-21'>{text}</p>
           </Link>
    </motion.div>
  )
}

export default GradientCard