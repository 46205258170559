import React, { useEffect, useState } from 'react'
import { authEndPoints } from '../../../../Services/AllApi'
import { apiConnector } from '../../../../Services/ApiConnector'

const Subs = () => {
    const {GET_ALL_EMAIL_SUBS_API}= authEndPoints
    const [emails, setemails] = useState([])
    useEffect(() => {
     const getAllSub = async()=>{
        try {
            const response = await apiConnector("GET", GET_ALL_EMAIL_SUBS_API)
            setemails(response.data.data)
        } catch (error) {
            console.log(error)
        }
     }
     getAllSub()
    }, [])
    console.log(emails)
    
  return (
    <div className=' mt-20 flex flex-col gap-4 items-center  '>
            <h2 className=' text-3xl font-semibold text-orange-500'>All subscribers</h2>
        <div className=' flex flex-col gap-3 w-full items-start'>
            {
                emails.map((data, index)=>{
                    return <p>{index+1}. {data?.email}</p>
                })
            }
        </div>
    </div>
  )
}

export default Subs