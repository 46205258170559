import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "./Swipercss.css"

const TempleImagesSwiper = ({templeDetail}) => {
  return (
    <div className="h-full flex items-center justify-center lg:mt-0 mt-20">
    <Swiper
      slidesPerView={1}
      spaceBetween={30}
      loop={true}
      centeredSlides={true}
      loopedSlides={true}
      modules={[Autoplay, Pagination, Navigation]}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
        stopOnLastSlide: false,
      }}
      navigation={true}
      pagination={{ clickable: true }}
      
      className="mySwiper  lg:max-h-[400px] flex items-center justify-center "
    > 
      <SwiperSlide className=" max-h-[400px] min-h-[400px]">
      
      <img src={templeDetail.image1?.startsWith("https") ?templeDetail.image1 : require(`../../../Assets/uploads/${templeDetail.image1}`)  } alt="" className="w-full h-full lg:object-cover object-contain lg:min-h-[350px] min-h-[300px] max-h-[300px] lg:max-h-[350px] rounded-xl" loading="lazy" />
       
      </SwiperSlide>
      <SwiperSlide className=" max-h-[400px] min-h-[400px]">
      <img src={templeDetail.image2?.startsWith("https") ?templeDetail.image2 : require(`../../../Assets/uploads/${templeDetail.image2}`)  } alt="" className="w-full h-full lg:object-cover object-contain lg:min-h-[350px] min-h-[300px] max-h-[300px] lg:max-h-[350px] rounded-xl" loading="lazy" />
      </SwiperSlide>
      <SwiperSlide className=" max-h-[400px] min-h-[400px]">\
      <img src={templeDetail.image3?.startsWith("https") ?templeDetail.image3 : require(`../../../Assets/uploads/${templeDetail.image3}`)  } alt="" className="w-full h-full lg:object-cover object-contain lg:min-h-[350px] min-h-[300px] max-h-[300px] lg:max-h-[350px] rounded-xl" loading="lazy" />
      </SwiperSlide>
      <SwiperSlide className=" max-h-[400px] min-h-[400px]">
      <img src={templeDetail.image4?.startsWith("https") ?templeDetail.image4 : require(`../../../Assets/uploads/${templeDetail.image4}`)  } alt="" className="w-full h-full lg:object-cover object-contain lg:min-h-[350px] min-h-[300px] max-h-[300px] lg:max-h-[350px] rounded-xl" loading="lazy" />
      </SwiperSlide>
      
    </Swiper>
  </div>
  )
}

export default TempleImagesSwiper