import React, { useEffect, useState } from 'react'
import { articleEndPoint } from '../../../../Services/AllApi'
import { apiConnector } from '../../../../Services/ApiConnector'
import toast from 'react-hot-toast'
import { RiDeleteBin7Line } from 'react-icons/ri'
import { MdOutlineModeEdit } from 'react-icons/md'
import EditArticleModal from './EditArticleModal'

const MyArticle = () => {
    const {GET_ALL_ARTICLE_API, DELETE_ARTICLE_API} = articleEndPoint
    const [articles, setarticles] = useState([])
    const [articleModal, setarticleModal] = useState(null)
    const getAllArticle = async()=>{
      try {
          const result = await apiConnector("GET", GET_ALL_ARTICLE_API)
          setarticles(result?.data?.data)
          
      } catch (error) {
          console.log(error)
      }
    }
    useEffect(() => {
      getAllArticle()
    }, [])

    const handleDelete = async(articleId)=>{
        try {
            const response = await apiConnector("DELETE", DELETE_ARTICLE_API, {articleId})
            toast.success("Deleted successfully")

      getAllArticle()
            
        } catch (error) {
            console.log(error)
        }
    }
    
  return (
   <>
     <div className=' mt-20 flex gap-10 flex-col justify-center'>
    <div className=" grid gap-9 sm:grid-cols-2 lg:grid-cols-3 place-items-center mt-5">
      {
        articles.map((data, index)=>{
            return <div key={index} className=' p-3 rounded-xl border-[1px] border-gray-200 flex flex-col justify-between min-h-[350px] cursor-pointer'>
            <img src={data?.image?.startsWith("https") ?data?.image : require(`../../../../Assets/uploads/${data?.image}`)  } alt="" className=' w-full rounded-xl max-h-[168px] object-cover' />
              <p className=' font-semibold'>{data.title}</p>
              <p>{data.description?.slice(0,150)}...</p>
              <div className=' w-full flex items-center justify-center flex-col gap-3'>
              <button className=' flex items-center gap-2  bg-yellow-500 p-2'
                  onClick={()=>{
                    setarticleModal({
                      articleId:data._id,
                      cancelHandler:()=>{
                        setarticleModal(null)
                      }
                    })
                  }}
                >Edit article<MdOutlineModeEdit /></button>
              <button className=' flex items-center gap-2 bg-red-500 p-2'
              onClick={()=> handleDelete(data._id)}
              >Delete<RiDeleteBin7Line /></button>
              </div>
            </div>
          })
      }
    </div>
  </div>
  {
    articleModal && <EditArticleModal articleId={articleModal.articleId} cancelHandler={articleModal.cancelHandler}/>
  }
   </>
  )
}

export default MyArticle