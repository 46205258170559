import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ArticleByIdNavbar from './ArticleByIdNavbar'
import { articleEndPoint } from '../../../../Services/AllApi'
import { apiConnector } from '../../../../Services/ApiConnector'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import Footer from '../../../common/Footer'
import ArticleCard from '../PujaPage/ArticleCard'
import FaqPage from '../FaqPage'
import GetRating from '../../../common/GetRating'
import { Helmet } from 'react-helmet-async'

const ArticleById = () => {
  
    const {slug} = useParams()
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
      } = useForm();
    const {GET_ARTICLE_BY_SLUG_API, CREATE_OFFLINE_CONTACT_API, GET_ALL_ARTICLE_API}= articleEndPoint
    const [Details, setDetails] = useState("")
    useEffect(() => {
        const getresult = async()=>{
            try {
                const response = await apiConnector("POST", GET_ARTICLE_BY_SLUG_API, {slug})
                setDetails(response.data.data)
            } catch (error) {
                console.log(error)
            }
        }
        getresult()
    }, [])

    const onSubmit= async(data)=>{
      const {fullName, email, phoneNum, gotra, dob, message} = data
      const articleId = Details?._id
      try {
        const response = await apiConnector("POST", CREATE_OFFLINE_CONTACT_API, {fullName, email, phoneNum, gotra, dob, message, articleId})
        toast.success("Booked offline puja")
        
      } catch (error) {
        console.log(error)
      }

    }

    const [articleDetails, setarticleDetails] = useState([])
    useEffect(() => {
     const getArticle = async()=>{
        try {
            const response = await apiConnector("GET", GET_ALL_ARTICLE_API)
            setarticleDetails(response?.data?.data)
            
        } catch (error) {
            console.log(error)
        }
     }
     getArticle()
    }, [])
    
  return (
   <>
    <Helmet>
      <title>Devsthana - Puja-articles</title>
<meta name="description" content={Details?.description}/>
<link rel='canonical' href={`/puja-articles/${slug}`} />
<meta property="og:title" content={`Devsthana - ${Details?.title}`} />
<meta property="og:type" content="website" />
<meta property="og:url" content={`https://www.devsthana.com/puja-articles/${slug}`} />

    </Helmet>
     <div className=' w-full '>
    <ArticleByIdNavbar title={slug}/>
    <div className=' max-w-screen-xl mx-auto flex lg:flex-row flex-col items-start justify-between gap-7 pb-7 px-2'>
            <div className=' flex flex-col gap-2 lg:w-[70%] w-full mt-32'>
                    <img src={Details?.image} alt={Details?.title} title={Details?.title} className=' rounded-md lg:w-[80%] max-h-[300px] object-fill w-full' />
                    <p className=' text-2xl font-bold'>{Details?.title}</p>
                    <p className=' text-base'>{Details?.description}</p>
                    <img src={Details?.image2} alt={Details?.title} title={Details?.title} className=' rounded-md lg:w-[80%] max-h-[300px] object-fill w-full' />
                    <p style={{whiteSpace:"pre-wrap"}} className=' text-[18px] list-disc' dangerouslySetInnerHTML={{__html:Details?.article}}></p>
            </div>

            <div className=' w-full flex flex-col flex-1 pt-[10%]'>
                <h2 className=' text-2xl font-semibold'>Book your puja for {Details?.title}</h2>


                <form
      className=" w-full flex flex-col gap-3 p-3   "
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className=" flex flex-col gap-2 px-2">
        <label className=" text-orange-500 font-semibold">
          Enter Full name<sup className=" text-red-500 text-sm">*</sup>
        </label>
        <input
          type="text"
          className=" w-full border-2 border-orange-500  rounded-md p-2"
          placeholder="Enter full name here"
          {...register("fullName", { required: true })}
        />
        {errors.fullName && (
          <span className=" text-red-500">fullName is required</span>
        )}
      </div> 
      <div className=" flex flex-col gap-2 px-2">
        <label className=" text-orange-500 font-semibold">
          Enter email<sup className=" text-red-500 text-sm">*</sup>
        </label>
        <input
          type="email"
          className=" w-full border-2 border-orange-500  rounded-md p-2"
          placeholder="Enter full name here"
          {...register("email", { required: false })}
        />
        
      </div>
      <div className=" flex flex-col gap-2 px-2">
        <label className=" text-orange-500 font-semibold">
          Enter phone number
          <sup className=" text-red-500 text-sm">*</sup>
        </label>
        <input
          type="number"
          placeholder="Whatsapp Number"
          className=" w-full border-2 border-orange-500  rounded-md p-2"
          {...register("phoneNum", {
            required: {
              value: true,
              message: "Please enter Phone Number",
            },
            maxLength: { value: 10, message: "Invalid Phone Number" },
            minLength: { value: 8, message: "Invalid Phone Number" },
          })}
        />
        {errors.phoneNum && (
          <span className=" text-red-500">phoneNum is required</span>
        )}
      </div>

      <div className=" flex flex-col gap-2 px-2">
        <label className=" text-orange-500 font-semibold">
          Enter gotra(optional)<sup className=" text-red-500 text-sm">*</sup>
        </label>
        <input
          type="text"
          className=" w-full border-2 border-orange-500  rounded-md p-2"
          placeholder="Enter full name here"
          {...register("gotra", { required: false })}
        />
      </div>

      <div className=" flex flex-col gap-1 px-2 w-full">
                <label className=" text-orange-500 font-semibold">
                  Enter Date of birth and time{" "}
                  <sup className=" text-red-500 text-sm">*</sup>
                </label>
                <input
                  type="datetime-local"
                  className=" w-full border-2 border-orange-500  rounded-md p-2"
                  {...register("dob", { required: true })}
                />
              </div>

              <div className=" flex flex-col gap-2 px-2">
        <label className=" text-orange-500 font-semibold">
          Enter message
          <sup className=" text-red-500 text-sm">*</sup>
        </label>
        <textarea
          type="text"
          className=" w-full border-2 border-orange-500  rounded-xl p-2"
          placeholder="Enter address here"
          {...register("message", { required: false })}
        />
      </div>
      
      
      <div className=" w-full items-center flex justify-center p-2">
        <button className=" bg-orange-500 px-5 py-2 rounded-xl hover:scale-105  bg-gradient-to-r from-orange-500 to-red-500 text-white transition-all duration-200">
          Submit
        </button>
      </div>
    </form>
            </div>
    </div>
   <div className='max-w-screen-xl mx-auto grid gap-9 sm:grid-cols-1 lg:grid-cols-2 place-items-center bg-white px-2 '>
   {
    articleDetails.length > 0 ? articleDetails?.slice(0,4)?.map((data, index)=>{
                return <ArticleCard key={index} image={data?.image} title={data?.title} description={data?.description} articleSlug={data?.slug} />
            }) : <p className=' h-full text-center'>No article found</p>
        }
   </div>
    </div>
    <div className=' w-full mt-20 max-w-screen-xl mx-auto px-2'>
            <div className=' w-full   flex items-start gap-5 flex-col'>
              <h2 className=' text-3xl font-bold'>What devotees Say about Devsthana Puja ?</h2>
              <p className=' text-xl  text-gray-500'>Reviews and Ratings from our customers who performed Puja with us.</p>
            </div>
            <div className=' w-full'>
              <GetRating/>
            </div>
      
            <div className=" max-w-screen-xl mx-auto mt-10   flex items-start gap-5 flex-col">
                <h2 className="text-[18px] md:text-[24px] font-bold">
                  Frequently asked Questions
                </h2>
              </div>
           <FaqPage ques={Details?.ques1} ans={Details?.ans1}/>
           <FaqPage ques={Details?.ques2} ans={Details?.ans2}/>
           <FaqPage ques={Details?.ques3} ans={Details?.ans3}/>
           <FaqPage ques={Details?.ques4} ans={Details?.ans4}/>
           {
            Details.ques5 != "" && <FaqPage ques={Details?.ques5} ans={Details?.ans5}/>
           }
           {
            Details.ques6 != "" && <FaqPage ques={Details?.ques6} ans={Details?.ans6}/>
           
           }
          </div>
    <Footer/>
   </>
  )
}

export default ArticleById