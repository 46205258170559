import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiConnector } from "../../../../Services/ApiConnector";
import { benifitEndPoints, packageEnPoints, pujaEndPoints } from "../../../../Services/AllApi";
import { FaPlus } from "react-icons/fa6";
import { RxCross1 } from "react-icons/rx";
import { setPooja, setSteps } from "../../../../Slices/PoojaSlice";
import ImageUpload from "./ImageUpload";

const Step2 = () => {
  const { pooja } = useSelector((state) => state.pooja);
  const { GET_ALL_PACKAGE_API } =  packageEnPoints;
  const [packages, setpackages] = useState([])
  const [poojaPackage, setpoojaPackage] = useState([]);
  const [key, setkey] = useState("")
  const dispatch = useDispatch();

  

  useEffect(() => {
    const getBenefits = async () => {
      try {
        const response = await apiConnector("GET", GET_ALL_PACKAGE_API);
        setpackages(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getBenefits();
  }, []);


  const handleRemove = (idToRemove) => {
    const updatedBenefits = poojaPackage.filter((id) => id !== idToRemove);
    setpoojaPackage(updatedBenefits);
  };
  
  const handleAdd = (idToAdd) => {
    const updatedBenefits = [...poojaPackage, idToAdd];
    setpoojaPackage(updatedBenefits);
  };
  

  const goBackHandler = () => {
    dispatch(setPooja(null));
    dispatch(setSteps(1));
  };

  const goNextHandler = () => {
    dispatch(setPooja({ ...pooja, poojaPackage }));
    dispatch(setSteps(3));
  };





  return (
    <div className=" flex flex-col gap-10">
     <h1 className=' text-xl lg:text-3xl font-bold uppercase'>Add package here</h1>
     <input type="search" placeholder="Enter the title" className=" px-3 p-2 rounded-xl border border-gray-500" onChange={(e)=> setkey(e.target.value)} value={key} />
      <div className=" mt-10 border border-gray-500 p-3 rounded-xl flex flex-col gap-4 flex-wrap">
        {packages.map((data, index) => {
          return (
            <div className=" w-[300px] h-fit flex gap-2 border border-gray-500 p-3 rounded-xl" key={index}>
             
              <div className=" flex flex-col gap-2 items-center">
                <p className=" text-xl font-bold">{data.price}</p>
                <p className=" text-md text-gray-500 leading-5">
                  {data.description}
                </p>
                {poojaPackage.includes(data._id) ? (
                  <button
                    className="px-3 p-1 rounded-xl bg-orange-500 flex items-center justify-center w-fit"
                    onClick={() => handleRemove(data._id)}
                    type="button"
                  >
                    Remove
                    <RxCross1 />
                  </button>
                ) : (
                  <button
                    className="px-3 p-1 rounded-xl bg-orange-500 flex items-center justify-center w-fit"
                    onClick={() => handleAdd(data._id)}
                    type="button"
                  >
                    Add
                    <FaPlus />
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className=" w-full flex items-center justify-between">
        <button
          type="button"
          className=" px-3 p-1 rounded-xl bg-orange-500"
          onClick={() => goBackHandler()}
        >
          Go back
        </button>
        {poojaPackage.length > 0 && (
          <button
            type="button"
            className=" px-3 p-1 rounded-xl bg-orange-500"
            onClick={() => goNextHandler()}
          >
            Go next
          </button>
        )}
      </div>
    </div>
  );
};

export default Step2;
