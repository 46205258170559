import React, { useState } from "react";
import logo from "../../Assets/devsthana logo.png";
import insta from "../../Assets/insta.svg"
import facebook from "../../Assets/facebook.svg"
import youtube from "../../Assets/youtube.svg"

import { Link } from "react-router-dom";
import EmailSubsribe from "./EmailSubsribe";
import footerImage from "../../Assets/footerTemple.jpeg"
import digital from "../../Assets/digital.svg"
import startup from "../../Assets/startup.svg"
import azadi from "../../Assets/azadi.svg"
import { FaArrowRight } from "react-icons/fa6";

const Footer = () => {
  const [showEmail, setshowEmail] = useState(false)
  return (
   <>
     <div className=" w-full flex flex-col  mt-7">
      <div className=" w-full flex justify-between lg:flex-row flex-col items-center gap-3 max-w-screen-xl mx-auto">
     <div className=" flex flex-col gap-3 items-center">
     <p className=" text-center lg:text-xl text-sm px-7 font-bold text-orange-500 lg:w-[500px]">A warm welcome awaits you as you explore our website, where every click leads to a world of discovery.</p> 
     <div className=" flex items-center gap-7 lg:flex-row flex-col">
     <Link to={"/puja"} className=" w-fit p-2 rounded-xl text-white font-bold cursor-pointer gradientButton h-fit relative overflow-hidden flex items-center gap-3 justify-center
              ">
      <div className=" w-[80px] h-[150px] bg-white -top-2 -left-2 rotate-45 absolute -skew-x-[45deg] opacity-40 animateButton"></div>
      Explore more puja <FaArrowRight /></Link>
      <Link to={"/temples"} className=" w-fit p-2 rounded-xl text-white font-bold cursor-pointer gradientButton relative overflow-hidden lg:flex items-center gap-3 justify-center  hidden
              ">
      <div className=" w-[80px] h-[150px] bg-white -top-2 -left-2 rotate-45 absolute -skew-x-[45deg] opacity-40 animateButton"></div>
      Connect to temples <FaArrowRight /></Link>
            
     </div>
      
     </div>
        <div>
          <img src={footerImage} title="temple image" alt="familyImage" />
        </div>
      </div>
      <div className=" w-full  p-3 bg-gradient-to-r from-[#FA6E2E] via-[#FD8622] to-[#FD7D2B] lg:h-[62px] px-4 bg-white dark:bg-gray-800 ">
        <div className="flex justify-between max-w-screen-xl gap-3 mx-auto lg:flex-row flex-col">
          <div className=" flex gap-2 items-center">
            <img src={logo} alt="devsthana" title="devsthana logo" className=" w-[100px] " />
          </div>
          <div className=" flex lg:flex-row  flex-col gap-3">
            <Link className=" uppercase font-bold text-white text-[13px]" to={"/aboutUs"}>About us</Link>
            <Link className=" uppercase font-bold text-white text-[13px]" to={"/terms-and-condition"}>Terms and conditions</Link>
            <Link className=" uppercase font-bold text-white text-[13px]" to={"/privacy-and-policy"}>Privacy and policy</Link>
            <Link className=" uppercase font-bold text-white text-[13px]" to={"/contact-us"}>Contact us</Link>
            <p className="uppercase font-bold text-white text-[13px] cursor-pointer" onClick={()=> setshowEmail(true)}>Subscribe Email</p>
          </div>
          <div className=" lg:hidden items-start gap-3 flex flex-row ">
                <img src={insta} alt="insta" title="insta" />
                <img src={youtube} alt="youtube" title="youtube" />
                <img src={facebook} alt="facebook" title="facebook" />
            </div>
        </div>
      </div>
      <div className=" w-full p-3 h-[62px] px-4 bg-white ">
        <div className=" w-full flex items-center lg:flex-row flex-col justify-between lg:gap-0 gap-7 max-w-screen-xl mx-auto">
        <div className=" flex items-center gap-3 lg:flex-row flex-col">
                <img src={digital} alt="digital" className=" h-[30px]" title="digital logo" />
                <img src={startup} alt="startup" className=" h-[30px]" title="start up logo" />
                <img src={azadi} alt="ajadi" className=" h-[30px]" title="azadi logo" />
            </div>
            <div className=" lg:flex items-center gap-3 hidden ">
            <img src={insta} alt="insta" title="insta" />
                <img src={youtube} alt="youtube" title="youtube" />
                <img src={facebook} alt="facebook" title="facebook" />
            </div>
        </div>
      </div>
    </div>
        {
          showEmail && <EmailSubsribe cancel={()=> setshowEmail(false)}/>
          }
   </>
  );
};

export default Footer;
