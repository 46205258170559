import React from 'react'
import { POOJA_PROCESS_DETAILS } from '../../../Data/PoojaProcess'
import "./Flag.css"

const PoojaProcess = () => {
  return (
         <div className=" flex flex-col gap-10 py-7">
            <h2 className="text-[18px] md:text-[24px] font-bold bg-gradient-to-r from-orange-600 to-red-500 text-transparent bg-clip-text ">Pooja Process</h2>
            <div className=" grid gap-9 sm:grid-cols-2 lg:grid-cols-3 mt-5">
                {
                    POOJA_PROCESS_DETAILS.map((data, index)=>{
                       return <div key={ index} className=' w-[300px] max-h-[200px] flex  gap-3 p-3'>
                        <div className=' w-[10px] h-[22px] text-lg font-bold bg-orange-500 flag flex items-center justify-center'>
                            {data.id}
                        </div>
                    <div className=' flex  gap-3 flex-col'>
                        <h2 className=' text-BLACK-90 text-[18px] md:text-[20px] font-bold'>{data.title}</h2>
                        <p className=' text-[15px] text-gray-500'>{data.description}</p>
                    </div>
                </div>
                    })
                }
            </div>
            </div>

  )
}

export default PoojaProcess