import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../common/Footer";
import Termscomponent from "./Termscomponent";
import { FaArrowRight } from "react-icons/fa6";

const TermsCondition = () => {
  return (
    <>
   <Termscomponent/>
   <div className=" w-full flex item-center justify-center">
   <Link to={"/contact-us"} className=" w-fit mt-10 p-2 rounded-xl text-white font-bold cursor-pointer gradientButton relative overflow-hidden flex items-center gap-3 justify-center
              ">
      <div className=" w-[80px] h-[150px] bg-white -top-2 -left-2 rotate-45 absolute -skew-x-[45deg] opacity-40 animateButton"></div>
      contact us <FaArrowRight /></Link>
   </div>
      <Footer />
    </>
  );
};

export default TermsCondition;
