import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import Card from "../component/core/Homecomponent/Card";
import GradientCard from "../component/core/Homecomponent/GradientCard";
import blueIcon1 from "../Assets/homepageIcons/bluePart/devotees.png";
import blueIcon2 from "../Assets/homepageIcons/bluePart/star rating.png";
import blueIcon4 from "../Assets/homepageIcons/bluePart/pooja services.png";
import { apiConnector } from "../Services/ApiConnector";
import { pujaEndPoints } from "../Services/AllApi";
import downIcon1 from "../Assets/homepageIcons/devotional/divine temple.png";
import downIcon2 from "../Assets/homepageIcons/devotional/hindu literature.png";
import downIcon5 from "../Assets/homepageIcons/devotional/puja & chadhava seva.png";
import downIcon6 from "../Assets/homepageIcons/devotional/sanatani community.png";
import LibraryCard from "../component/core/Homecomponent/LibraryCard";
import libraryImage1 from "../Assets/homepageIcons/articles/arti-01.png";
import libraryImage2 from "../Assets/homepageIcons/articles/chalisa.png";
import libraryImage3 from "../Assets/homepageIcons/articles/om.png";
import libraryImage4 from "../Assets/book-stand-500x500.jpg";
import GetRating from "../component/common/GetRating";
import Footer from "../component/common/Footer";
import { motion } from "framer-motion";
import matag from "../Assets/mataji.png";
import TextAnimation from "../component/core/Homecomponent/TextAnimation";
import homePageBg from "../Assets/main pagebg.jpg";
import traditionalDesign from "../Assets/homepageIcons/Decorative circle.png";
import { Helmet } from "react-helmet-async";
import Animatedround from "../component/core/Homecomponent/Animatedround";
import "../App.css"
import bookImage from "../Assets/homepageIcons/bluePart/book.png"
import offlinePuja from "../Assets/offlineHome.jpg"
import Spline from '@splinetool/react-spline';

const Homepage = () => {
  const { GET_ALL_PUJA_API } = pujaEndPoints;
  const [poojaDetails, setpoojaDetails] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      try {
        const response = await apiConnector("GET", GET_ALL_PUJA_API);
        setpoojaDetails(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };
    getDetails();
  }, []);

  return (
    <>
    <Helmet>
      <title>Devsthana - Home</title>
      <meta name="theme-color" content="#FFA500" />
      <meta name="description" content="Devotional needs, all in one Devsthana.  Bring divine blessings home with puja services tailored for you and your loved ones." property="og:title" />
      <link rel='canonical' href='/' />
      <meta property="og:title" content="online pooja website" />
<meta property="og:type" content="Online pooja" />
<meta property="og:url" content="https://www.devsthana.com" />
    </Helmet>
      <div className=" flex items-center justify-between flex-col min-h-screen overflow-x-hidden">
        <div className=" w-full bg-orange-100 relative">
          <img
            src={homePageBg}
            alt="Puja"
            className=" w-screen h-screen object-cover opacity-45"
            title="devsthana"
          />
          <div className=" absolute top-14 max-w-screen-xl lg:px-0 lg:py-40 py-0  flex lg:flex-row flex-col-reverse items-center lg:mt-0 -mt-20 justify-between pt-32 mx-auto gap-3 ">
            <motion.div
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 100 }}
              transition={{ duration: 1 }}
              className=" w-full lg:w-[80%] flex flex-col  lg:gap-6 gap-1 lg:pl-32 lg:pt-0 pt-14 lg:px-0 px-4 lg:items-start items-center"
            >
              <p className=" text-2xl lg:text-4xl font-bold w-full lg:w-[60%] leading-snug">
                Devotional needs, all in one{" "}
                <span className=" text-red-600"> Devsthana</span>.
              </p>
              <p className=" w-full lg:w-[70%] text-[17px] text-gray-600 leading-snug">
                Establish Temples. Stay Updated on Fasts and Festivals. Trusted by
                Over 10 thousand+ People.
              </p>
             <div className=" flex items-center gap-7 lg:flex-row flex-col">
             <Link to={"/puja"} className=" w-fit p-2 rounded-xl text-white font-bold cursor-pointer gradientButton relative overflow-hidden flex items-center gap-3 justify-center
              ">
      <div className=" w-[80px] h-[150px] bg-white -top-2 -left-2 rotate-45 absolute -skew-x-[45deg] opacity-40 animateButton"></div>
      Explore more puja <FaArrowRight /></Link>

      <Link to={"/puja-articles"} className=" w-fit p-2 rounded-xl text-white font-bold cursor-pointer gradientButton relative overflow-hidden lg:flex items-center gap-3 justify-center  hidden
              ">
      <div className=" w-[80px] h-[150px] bg-white -top-2 -left-2 rotate-45 absolute -skew-x-[45deg] opacity-40 animateButton"></div>
      Book offline puja <FaArrowRight /></Link>
             </div>
            
            </motion.div>
            <div className=" w-[70%] lg:w-[10%] py-20  flex items-center justify-center">
              {/* <img
                src={matag}
                alt="mata g"
                className="lg:w-full  object-cover scale-x-[-1] lg:h-full w-[300px] "
                title="Image"
              /> */}
            <Animatedround/>
            </div>
          </div>
        </div>
        <TextAnimation />

        <div className=" relative py-8 px-4 md:px-0 mx-auto max-w-screen-xl lg:py-12 lg:px-0 flex flex-col gap-4">
          <img
            src={traditionalDesign}
            alt="Design"
            className=" absolute w-[300px]  -left-52 -z-10 top-0 opacity-60 animate-spin"
          />
          <img
            src={traditionalDesign}
            alt="Design"
            className=" absolute w-[300px]  -right-52 lg:z-10 -z-10 bottom-0 opacity-60 animate-spin"
          />
          <div className="mx-auto max-w-screen-md text-left md:text-center lg:mb-0 flex items-center justify-center flex-col">
            <h2 className=" lg:text-4xl text-2xl  leading-32 md:text-[36px] md:font-bold md:leading-49 mb-4 tracking-tight bg-gradient-to-r from-orange-600 to-red-500 text-center text-transparent bg-clip-text  font-bold">
              Bring divine blessings home with puja services tailored for you
              and your loved ones.
            </h2>
            <p className="text-16 text-center font-normal leading-21 md:text-20 md:font-normal md:leading-27 text-gray-600 sm:text-xl dark:text-gray-400 block mb-3">
              Experience divine blessings from the comfort of your home by
              booking personalized pujas at renowned temples across India, with
              the added convenience of receiving a video of the ceremony and
              blessed prasad.
            </p>
            <Link
              className=" flex items-center gap-3 text-orange-600 font-bold"
              to={"/puja"}
            >
              <p className=" hover:underline cursor-pointer">
                Explore All Puja
              </p>
              <FaArrowRight />
            </Link>
          </div>
          <div className=" grid gap-9 sm:grid-cols-2 lg:grid-cols-3 bg-white">
            {poojaDetails.slice(0, 3).map((data, index) => {
              return (
                <Card
                  key={index}
                  image={data.image1}
                  title={data.title}
                  text1={data.address}
                  text2={data.date}
                  poojaId={data.slug}
                />
              );
            })}
          </div>
        </div>

        {/* <div className=" w-full  bg-gray-50">
          <div className="py-8 px-4 md:px-0 mx-auto max-w-screen-xl lg:py-12 lg:px-0 flex flex-col gap-10">
            <div className="mx-auto max-w-screen-sm text-left md:text-center lg:mb-0 flex items-center justify-center flex-col ">
              <h2 className=" text-4xl font-bold leading-32 md:text-36 md:font-bold md:leading-49 mb-4 tracking-tight text-gray-900">
                Complete Panchang
              </h2>
              <p className="text-16 font-normal leading-21 md:text-20 md:font-normal md:leading-27 text-gray-600 sm:text-xl dark:text-gray-400 block mb-3">
                Devsthana Panchang offers you all the important information
                about Tithi, Auspicious-Inauspicious timings, and upcoming fasts
                and festivals.
              </p>
            </div>

            <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-8 xl:gap-8 md:space-y-0 ">
              <GradientCard
                gradient={"bg-gradient-to-r from-[#fbc69c] to-[#fde8d7]"}
                icon={icon1}
                title={"Auspicious-Inauspicious timings"}
                text={
                  "Check out Auspicious and Inauspicious Timings for your city."
                }
              />

              <GradientCard
                gradient={"bg-gradient-to-r from-[#b4ddff] to-[#e1f1ff]"}
                icon={icon2}
                title={"Rahukal and Gulik Kaal"}
                text={"Check out Rahukal and Gulik Kaal for your city."}
              />
              <GradientCard
                gradient={"bg-gradient-to-r from-[#fbbab3] to-[#ffdeda]"}
                icon={icon3}
                title={"Sunrise-Sunset"}
                text={"Know the Sunrise and Sunset timings for your city."}
              />
              <GradientCard
                gradient={"bg-gradient-to-r from-[#e0b3fe] to-[#ebcdff]"}
                icon={icon4}
                title={"Upcoming Festivals"}
                text={
                  "Check out the upcoming festivals, their Puja Vidhi and Vrat Katha."
                }
              />
            </div>
          </div>
        </div> */}

        <div className="w-full bg-gradient-to-r  from-[#121f36] to-[#162e59] imageBg ">
          <div className="items-center flex lg:flex-row flex-col justify-between py-8 px-4 md:px-0 mx-auto max-w-screen-xl   lg:py-12 lg:px-0">
            <div className=" flex flex-col gap-6 lg:w-[50%] h-full w-full  justify-center">
              <p className=" text-[#72A0F1] font-bold leading-25 text-[18px]">
                Bringing sacred rituals to your doorstep, our trusted services
                have earned the faith of millions in India and over 100,000
                worldwide.
              </p>
              <h1 className="text-[22px] font-extrabold leading-[32px]  mt-3 mb-4 tracking-tight text-white md:text-2xl ">
                Embraced by countless devotees across India, we proudly stand as
                the nation's most cherished devotional website.
              </h1>
              <p className="block text-white text-md font-normal leading-21 ">
                Our mission is to accompany a billion Indians on their spiritual
                odyssey, nurturing happiness, peace, and fulfillment every step
                of the way.
              </p>
            </div>

            <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0  w-full lg:w-[50%]  ">
              <GradientCard
                icon={blueIcon1}
                title={"10 Thousand+ Devotees"}
                textcolor={"text-white"}
                text={"have trusted us in their devotional journey"}
              />
              <GradientCard
                icon={blueIcon2}
                title={"4.6 star rating"}
                textcolor={"text-white"}
                text={
                  "Over 1 Lakh devotees express their love for us on Devsthana"
                }
              />
              <GradientCard
                icon={bookImage}
                title={"50+ Article content"}
                textcolor={"text-white"}
                text={
                  "Explore more articles and indulge in the joy of learning."
                }
              />
              <GradientCard
                icon={blueIcon4}
                title={"5K+ Successful Pooja Services"}
                textcolor={"text-white"}
                text={
                  "Millions of devotees have commenced Pooja at famous temples of India with us to seek God's grace."
                }
              />
            </div>
          </div>
        </div>

      

        <div className="py-8 px-4 md:px-0 mx-auto w-full max-w-screen-xl lg:px-0 flex flex-col gap-10 ">
          <div className=" text-left md:text-center lg:mb-0 flex items-center justify-center flex-col ">
            <h2 className=" text-4xl font-bold leading-32 md:text-36 md:font-bold md:leading-49 mb-4 tracking-tight bg-gradient-to-r from-orange-600 to-red-500 text-center text-transparent bg-clip-text  ">
              Reviews & Ratings
            </h2>
            <p className="text-16 font-normal text-center leading-21 md:text-20 md:font-normal md:leading-27 text-gray-600 sm:text-xl dark:text-gray-400 block mb-3">
              Discover what our cherished devotees have to say about their
              experiences with Devsthana.
            </p>
          </div>
          <div className=" w-full  ">
            <GetRating />
          </div>
        </div>

        <div className="py-8 px-4 md:px-0 mx-auto max-w-screen-xl lg:py-12 lg:px-0 flex flex-col gap-10">
          <div className=" text-left md:text-center lg:mb-0 flex items-center justify-center flex-col ">
            <h2 className=" lg:text-4xl text-2xl  leading-32 md:text-36 md:font-bold md:leading-49 mb-4 tracking-tight bg-gradient-to-r from-orange-600 to-red-500 text-transparent bg-clip-text  font-bold text-center">
              One Website for all your devotional needs
            </h2>
            <p className="text-16 font-normal text-center leading-21 md:text-20 md:font-normal md:leading-27 text-gray-600 sm:text-xl dark:text-gray-400 block mb-3">
              Devsthana offers these incredible features for you, all for free.
              Embark on your devotional journey today and experience the divine.
            </p>
          </div>
          <div className="space-y-8 md:grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 md:gap-4 xl:gap-4 md:space-y-0 ">
            <GradientCard
              icon={downIcon1}
              title={"Divine Temple"}
              text={
                "Establish your temple on your phone, devoted to your cherished deities, and receive their blessings wherever you are."
              }
              gradient={"white"}
              textcolor={"text-black"}
              link={"/temples"}
            />
            <GradientCard
              icon={downIcon2}
              title={"Hindu Literature"}
              text={
                "Access curated books, articles, and videos on Sanatan Dharma."
              }
              gradient={"white"}
              textcolor={"text-black"}
              link={"/articles"}
            />
            {/* <GradientCard
              icon={downIcon3}
              title={"Devotional Music"}
              text={
                "Get access to 5000+ Ad-Free Devotional Music. Listen to Aarti, Mantra, Bhajan, and Chalisa, and emerge yourself into the divinity."
              }
              gradient={"white"}
              textcolor={"text-black"}
            /> */}
            {/* <GradientCard
              icon={downIcon4}
              title={"Panchang, Horoscope & Festivals"}
              text={
                "Get regular updates on Daily Horoscope, Panchang, and upcoming Fasts- Festivals."
              }
              gradient={"white"}
              textcolor={"text-black"}
            /> */}
            <GradientCard
              icon={downIcon5}
              title={"Puja and Chadhava Seva"}
              text={
                "Arrange personalized Puja and Chadhava Seva in your and your family's name at 1000+ renowned temples throughout India"
              }
              gradient={"white"}
              textcolor={"text-black"}
              link={"/puja-articles"}

            />
            <GradientCard
              icon={downIcon6}
              title={"Sanatani Community"}
              text={
                "Join India's largest devotional community and connect with Sanatanis worldwide."
              }
              gradient={"white"}
              textcolor={"text-black"}
              link={"/astrology"}
            />
          </div>
        </div>

        <div className="py-8 px-4 md:px-0 mx-auto max-w-screen-xl lg:py-12 lg:px-0 flex flex-col gap-10">
          <div className=" text-left md:text-center lg:mb-0 flex items-center justify-center flex-col ">
            <h2 className=" lg:text-4xl text-2xl  leading-32 md:text-36 md:font-bold md:leading-49 mb-4 tracking-tight bg-gradient-to-r from-orange-600 to-red-500 text-transparent bg-clip-text  font-bold text-center">
              Explore captivating articles covering upcoming fasts, festivals,
              and all aspects of Sanatan Dharma.
            </h2>
            <p className="text-16 font-normal leading-21 md:text-20 text-center md:font-normal md:leading-27 text-gray-600 sm:text-xl dark:text-gray-400 block mb-3">
              Discover fascinating articles on upcoming fasts, festivals, and
              all things related to Sanatan Dharma.
            </p>
            <Link
              className=" flex items-center gap-3 text-orange-600 font-bold"
              to={"/articles"}
            >
              <p className=" hover:underline cursor-pointer">Read all</p>
              <FaArrowRight />
            </Link>
          </div>
          <div className="grid gap-6 lg:gap-12 md:grid-cols-4 ">
            <LibraryCard
              image={libraryImage1}
              title={"Aarti"}
              description={
                "Find complete lyrics of all the famous Aartis and easily worship your beloved God."
              }
              path={"/articles/Aarti"}
            />
            <LibraryCard
              image={libraryImage2}
              title={"Chalisa"}
              description={
                "You will get complete Chalisa of all the deities. Read Chalisa during the Pooja of your beloved deities and seek their grace."
              }
              path={"/articles/Chalisa"}
            />
            <LibraryCard
              image={libraryImage3}
              title={"Mantra"}
              description={
                "Here you will find all the powerful mantras for peace of mind. Chant these mantras and remove all the obstacles from life."
              }
              path={"/articles/Mantra"}
            />
            <LibraryCard
              image={libraryImage4}
              title={"Puja articles"}
              description={
                "We have brought the precious knowledge of Ayurveda for you, these remedies will help you lead a healthy life."
              }
              path={"/puja-articles"}
            />
          </div>

        </div>

        <div className="py-8 px-4 md:px-0 mx-auto max-w-screen-xl lg:py-12 lg:px-0 flex flex-col items-center gap-4">
        <h2 className=" lg:text-4xl text-2xl  leading-32 md:text-36 md:font-bold md:leading-49 mb-4 tracking-tight bg-gradient-to-r from-orange-600 to-red-500 text-transparent bg-clip-text  font-bold text-center">
              Book offline pooja
            </h2>
            <p className="text-16 font-normal leading-21 md:text-20 text-center md:font-normal md:leading-27 px-6  text-gray-600 sm:text-xl dark:text-gray-400 block mb-3">
            Experience divine blessings with our offline puja services, connecting you with sacred rituals and spiritual fulfillment in your community.
            </p>
          <img src={offlinePuja} alt="" className=" w-[400px]" />
          <Link to={"/puja"} className=" w-fit p-2 rounded-xl text-white font-bold cursor-pointer gradientButton relative overflow-hidden flex items-center gap-3 justify-center
              ">
      <div className=" w-[80px] h-[150px] bg-white -top-2 -left-2 rotate-45 absolute -skew-x-[45deg] opacity-40 animateButton"></div>
      Book offline puja <FaArrowRight /></Link>
        </div>


      </div>
      <Footer />
    </>
  );
};

export default Homepage;
