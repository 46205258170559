import React, { useEffect, useState } from 'react'
import { authEndPoints } from '../../../../Services/AllApi'
import { apiConnector } from '../../../../Services/ApiConnector'
import { formattedDate } from '../../../../Utils/DateFormatter'

const ContactData = () => {
    const {GET_ALL_CONTACT_API} = authEndPoints
    const [Details, setDetails] = useState([])
    useEffect(() => {
        const getData = async()=>{
            try {
                const response = await apiConnector("GET", GET_ALL_CONTACT_API)
                setDetails(response.data.data)
            } catch (error) {
                console.log(error)
            }
        }
        getData()
    }, [])
    
  return (
    <div className='  mt-20 flex gap-10 flex-col items-center justify-center'>
    <h1 className='text-3xl font-bold uppercase text-orange-500'>Contacts</h1>
    <div className=' w-full grid gap-9 sm:grid-cols-2 lg:grid-cols-3'>
        {
            Details.map((data,index)=>{
                return <div className=' flex items-center gap-1 flex-col border p-2 border-gray-500 rounded-sm'>
                    <p>{data?.fullName}</p>
                    <p>{data?.email}</p>
                    <p>{data?.phoneNum}</p>
                    <p>{data?.message}</p>
                    <p>{formattedDate(data?.createdAt)}</p>
                </div>
            })
        }
    </div>
</div>
  )
}

export default ContactData