import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { pujaEndPoints } from "../../../Services/AllApi";
import { apiConnector } from "../../../Services/ApiConnector";
import PoojaSwiper from "./PoojaSwiper";
import { GiByzantinTemple } from "react-icons/gi";
import Timer from "./Timer";
import { FaArrowRight } from "react-icons/fa6";
import PoojaProcess from "./PoojaProcess";
import PaymentCard from "./PaymentCard";
import PoojaNavbar from "./PoojaNavbar";
import GetRating from "../../common/GetRating";
import FAQS from "./FAQS";
import { IoMdCalendar } from "react-icons/io";
// import user1 from "../../../Assets/user/imag1.avif";
import user2 from "../../../Assets/user/image2.avif";
import user3 from "../../../Assets/user/image3.avif";
import user4 from "../../../Assets/user/image4.avif";
import user5 from "../../../Assets/user/image5.avif";
import Footer from "../../common/Footer";
import { formattedDate } from "../../../Utils/DateFormatter";
import { Helmet } from "react-helmet-async";
import star from "../../../Assets/image (1).avif";
import image2 from "../../../Assets/image2.avif";
import prasad from "../../../Assets/prasad.png";
import Benefitsection from "./Benefitsection";
import { FaDotCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setShowFrom } from "../../../Slices/PaymentSlice";
import FormModal from "./FormModal";
import icon1 from "../../../Assets/poojaIcons/icon1.jpg"
import icon2 from "../../../Assets/poojaIcons/icon2.png"
import icon3 from "../../../Assets/poojaIcons/icon3.jpg"
import icon4 from "../../../Assets/poojaIcons/icon4.jpeg"
import icon5 from "../../../Assets/poojaIcons/icon5.png"
import icon6 from "../../../Assets/poojaIcons/icon6.jpg"

const PoojaById = () => {
  const { slug } = useParams();
  const { GET_PUJA_BY_ID_API } = pujaEndPoints;
  const [poojadetail, setpoojadetail] = useState("");
  useEffect(() => {
    const getDetails = async () => {
      try {
        const response = await apiConnector("POST", GET_PUJA_BY_ID_API, {
          slug,
        });
        setpoojadetail(response?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    getDetails();
  }, [slug]);
  const targetDate = new Date(poojadetail?.date).getTime();
  const targetDiv = useRef(null);

  const handleScrolltoDiv = () => {
    if (targetDiv.current) {
      const yOffset = -0; // Adjust this value to set the margin
      const y =
        targetDiv.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const [textColor1, settextColor1] = useState("");
  const [textColor2, settextColor2] = useState("");
  const [textColor3, settextColor3] = useState("");
  const [textColor4, settextColor4] = useState("");
  const [textColor5, settextColor5] = useState("");
  const [textColor6, settextColor6] = useState("");
  const [textColor7, settextColor7] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const aboutPooja = document.getElementById("aboutPooja");
      const poojaBenefits = document.getElementById("poojaBenefits");
      const aboutPujaProcess = document.getElementById("aboutPujaProcess");
      const aboutTemple = document.getElementById("aboutTemple");
      const aboutPayment = document.getElementById("aboutPayment");
      const aboutReviews = document.getElementById("reviews");
      const aboutFAQS = document.getElementById("FAQS");

      const isInView = (element, scrollPos) => {
        if (!element) return false;

        const rect = element.getBoundingClientRect();
        const buffer = -300; // Adjust this value as needed

        return (
          rect.top - buffer <= window.innerHeight && rect.bottom + buffer >= 0
        );
      };
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;

      if (isInView(aboutPooja, scrollPosition)) {
        settextColor1("text-orange-500");
      } else {
        settextColor1("text-gray-500 ");
      }

      if (isInView(poojaBenefits, scrollPosition)) {
        settextColor2("text-orange-500");
      } else {
        settextColor2("text-gray-500 ");
      }

      if (isInView(aboutPujaProcess, scrollPosition)) {
        settextColor3("text-orange-500");
      } else {
        settextColor3("text-gray-500 ");
      }
      if (isInView(aboutTemple, scrollPosition)) {
        settextColor4("text-orange-500");
      } else {
        settextColor4("text-gray-500 ");
      }
      if (isInView(aboutPayment, scrollPosition)) {
        settextColor5("text-orange-500");
      } else {
        settextColor5("text-gray-500 ");
      }
      if (isInView(aboutReviews, scrollPosition)) {
        settextColor6("text-orange-500");
      } else {
        settextColor6("text-gray-500 ");
      }
      if (isInView(aboutFAQS, scrollPosition)) {
        settextColor7("text-orange-500");
      } else {
        settextColor7("text-gray-500 ");
      }

      // Repeat the above process for other sections...
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const aboutpoojaDiv = useRef(null);
  const aboutBenefitDiv = useRef(null);
  const aboutprocesstDiv = useRef(null);
  const aboutTempleDiv = useRef(null);
  const aboutPaymentDiv = useRef(null);
  const aboutReviewsDiv = useRef(null);
  const aboutFAQSDiv = useRef(null);

  const handleScrolltoDivToPooja = () => {
    if (aboutpoojaDiv.current) {
      const yOffset = -300; // Adjust this value to set the margin
      const y =
        aboutpoojaDiv.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  const handleScrolltoDivToBenefit = () => {
    if (aboutBenefitDiv.current) {
      const yOffset = -260; // Adjust this value to set the margin
      const y =
        aboutBenefitDiv.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  const handleScrolltoDivToProcess = () => {
    if (aboutprocesstDiv.current) {
      const yOffset = -160; // Adjust this value to set the margin
      const y =
        aboutprocesstDiv.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };
  const handleScrolltoDivToTemple = () => {
    if (aboutTempleDiv.current) {
      const yOffset = -160; // Adjust this value to set the margin
      const y =
        aboutTempleDiv.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const handleScrolltoDivToreviews = () => {
    if (aboutReviewsDiv.current) {
      const yOffset = -200; // Adjust this value to set the margin
      const y =
        aboutReviewsDiv.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const handleScrolltoDivToFAQS = () => {
    if (aboutFAQSDiv.current) {
      const yOffset = -300; // Adjust this value to set the margin
      const y =
        aboutFAQSDiv.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const { showForm } = useSelector((state) => state.payment);
  const dispatch = useDispatch();
  const [packageId, setpackageId] = useState("");

  const containerStyles = {
    background: "linear-gradient(to bottom, #FFEEDE, white)",
  };
  const containerStyles2 = {
    background: "linear-gradient(to bottom, #ECF1FE, white)",
  };
  const containerStyles3 = {
    background: "linear-gradient(to bottom, #FFE6DE, white)",
  };
  const containerStyles4 = {
    background: "linear-gradient(to bottom, #F7F3FE, white)",
  };
  return (
    <>
      <Helmet>
        <title>Devsthana - Puja</title>
        <meta name="description" content={poojadetail?.description} />
        <link rel="canonical" href={`/puja-articles/${slug}`} />
        <meta
          property="og:title"
          content={`Devsthana - ${poojadetail?.title}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.devsthana.com/puja-articles/${poojadetail?.slug}`}
        />
      </Helmet>
      <div className=" w-full min-h-screen max-w-screen-xl mx-auto lg:px-0 px-5  ">
        <PoojaNavbar poojaTitle={poojadetail?.title?.slice(0, 100)} />
        <div className=" flex flex-col gap-20 lg:mt-40  ">
          <div className=" flex items-center lg:flex-row flex-col justify-center lg:justify-between  lg:px-0 px-2">
            <div className=" w-full lg:w-[45%] ">
             {

              poojadetail &&  <PoojaSwiper poojaDetails={poojadetail} />
             }
            </div>
            <div className=" lg:w-[45%] h-full flex flex-col gap-2 lg:mt-0 -mt-14 ">
              <h2 className=" text-[22px] lg:text-2xl font-bold">
                {poojadetail.title?.slice(0, 100)}...
              </h2>
              <div className=" flex flex-col items-start gap-1">
                <div className=" flex gap-4 text-[16px] items-center justify-center text-gray-700">
                  <GiByzantinTemple className=" text-orange-500" />
                  {poojadetail.address}
                </div>

                {poojadetail?.date && (
                  <div className=" flex gap-4 text-[16px] items-center justify-center text-gray-700">
                    <IoMdCalendar className=" text-orange-500" />
                    {formattedDate(poojadetail?.date)}
                  </div>
                )}
                {poojadetail?.date && (
                  <div>
                    <p className=" font-semibold text-gray-500">
                      Pooja starts in:
                    </p>
                    <Timer targetDate={targetDate} />
                  </div>
                )}
                <div className=" flex items-center gap-10 ">
                  <div className=" flex flex-col gap-1">
                    <img
                      src={image2}
                      title="watch video"
                      alt="watch video"
                      className=" w-12"
                    />
                    <p className=" text-sm text-gray-500">Watch Puja Video</p>
                  </div>

                  <div className=" flex flex-col gap-1 ">
                    <img
                      src={prasad}
                      title="prasad"
                      alt="prasad"
                      className=" w-12"
                    />
                    <p className=" text-sm text-gray-500">Prasad Delivery</p>
                  </div>

                  <div className=" flex flex-col gap-1 ">
                    <img src={star} title="star" alt="star" className=" w-12" />
                    <p className=" text-sm text-gray-500">5.0 Puja Rating</p>
                  </div>
                </div>
                <div className=" flex">

                  <img
                    src={user2}
                    alt="user"
                    className=" w-[50px] h-[50px] border-2 border-white rounded-full object-cover -ml-6"
                  />
                  <img
                    src={user3}
                    alt="user"
                    className=" w-[50px] h-[50px] border-2 border-white rounded-full object-cover -ml-6"
                  />
                  <img
                    src={user4}
                    alt="user"
                    className=" w-[50px] h-[50px] border-2 border-white rounded-full object-cover -ml-6"
                  />
                  <img
                    src={user5}
                    alt="user"
                    className=" w-[50px] h-[50px] border-2 border-white rounded-full object-cover -ml-6"
                  />
                </div>
                <p className=" text-md">
                  Till now{" "}
                  <span className=" text-orange-500">1,00,0+Devotees</span> have
                  participated in Pujas conducted by Devsthana Puja Seva.
                </p>
                <button
                  className="  w-full p-2 rounded-xl text-white font-bold cursor-pointer gradientButton relative overflow-hidden lg:flex gap-2 hidden items-center justify-center"
                  onClick={() => handleScrolltoDiv()}
                >
                  <div className=" w-[80px] h-[150px] bg-white -top-2 -left-2 rotate-45 absolute -skew-x-[45deg] opacity-40 animateButton"></div>
                  Participate <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className=" sticky mt-3 lg:top-[104px] top-[69px] z-10 w-full  border-b bg-white border-gray-300  mb-7 ">
          {/* <div className=' max-w-screen-xl mx-auto p-3 text-gray-500 flex items-center justify-between lg:text-lg text-[14px] font-bold gap-20 w-full overflow-auto'> */}
          <div className=" w-full flex items-center justify-between gap-7 overflow-auto p-3 text-gray-500 flex-nowrap ">
            <p
              className={`${textColor1} cursor-pointer min-w-[100px] `}
              onClick={handleScrolltoDivToPooja}
            >
              About Puja
            </p>
            <p
              className={`${textColor2}  cursor-pointer min-w-[100px]`}
              onClick={handleScrolltoDivToBenefit}
            >
              Benefits
            </p>
            <p
              className={`${textColor3}  cursor-pointer min-w-[100px]`}
              onClick={handleScrolltoDivToProcess}
            >
              Process
            </p>
            <p
              className={`${textColor4}  cursor-pointer min-w-[120px]`}
              onClick={handleScrolltoDivToTemple}
            >
              Temple Details
            </p>
            <p
              className={`${textColor5}  cursor-pointer min-w-[100px]`}
              onClick={handleScrolltoDiv}
            >
              Packages
            </p>
            <p
              className={`${textColor6}  cursor-pointer min-w-[100px]`}
              onClick={handleScrolltoDivToreviews}
            >
              Reviews
            </p>
            <p
              className={`${textColor7}  cursor-pointer min-w-[100px]`}
              onClick={handleScrolltoDivToFAQS}
            >
              FAQs
            </p>
          </div>
        </div>
        <div className=" w-full min-h-screen flex lg:mt-0 mt-3 flex-col gap-8">
          <div
            className="flex flex-col gap-3"
            id="aboutPooja"
            ref={aboutpoojaDiv}
          >
            <h2 className=" text-[18px] md:text-[24px] font-bold">
              {poojadetail?.title}
            </h2>
            <p className=" text-gray-500 lg:text-lg text-[15px]">
              {poojadetail?.description}
            </p>
          </div>
          <div className=" w-full h-4 bg-gray-300 bg-opacity-60"></div>
          <div className=" flex flex-col gap-10">
            <h2 className=" text-[18px] md:text-[24px] font-bold bg-gradient-to-r from-orange-600 to-red-500 text-transparent bg-clip-text ">
              Pooja Benefits
            </h2>
            <div
              className="grid gap-9 sm:grid-cols-2 lg:grid-cols-3 mt-5"
              id="poojaBenefits"
              ref={aboutBenefitDiv}
            >
              <Benefitsection
                title={poojadetail?.benefit1}
                description={poojadetail?.benefitDesc1}
                icons={icon1}
              />
              <Benefitsection
                title={poojadetail?.benefit2}
                description={poojadetail?.benefitDesc2}
                icons={icon2}
              />
              {poojadetail?.benefit3 && (
                <Benefitsection
                  title={poojadetail?.benefit3}
                  description={poojadetail?.benefitDesc3}
                  icons={icon3}
                />
              )}
              {poojadetail?.benefit4 && (
                <Benefitsection
                  title={poojadetail?.benefit4}
                  description={poojadetail?.benefitDesc4}
                  icons={icon4}
                />
              )}{" "}
                <Benefitsection
                  title={poojadetail?.benefit5}
                  description={poojadetail?.benefitDesc5}
                  icons={icon5}
                />
              {/* {poojadetail?.benefit5 && (
              )} */}
                <Benefitsection
                  title={poojadetail?.benefit6}
                  description={poojadetail?.benefitDesc6}
                  icons={icon6}
                />
              {/* {poojadetail?.benefit6 && (
              )} */}
            </div>
            <div className=" w-full h-4 bg-gray-300 bg-opacity-60"></div>
            <div id="aboutPujaProcess" ref={aboutprocesstDiv}>
              <PoojaProcess />
            </div>{" "}
            <div className=" w-full h-4 bg-gray-300 bg-opacity-60"></div>
            <div
              className=" flex flex-col gap-4 py-7"
              id="aboutTemple"
              ref={aboutTempleDiv}
            >
              <h2 className="text-[18px] md:text-[24px] font-bold">
                {poojadetail.templeName}
              </h2>
              <div className=" flex items-center lg:flex-row flex-col justify-between w-full">
             {
              poojadetail &&  <img
            src={
              poojadetail?.image2?.startsWith("https")
                ? poojadetail?.image2
                : require(`../../../Assets/uploads/${poojadetail?.image2}`)
            }
            className=" w-full lg:w-[45%] h-[70%] object-cover rounded-xl"
            loading="lazy"
          />
             }
               
                <div className=" w-full flex flex-col items-center  gap-4 px-10">
                  <pre
                    className=" w-full text-gray-500 text-base leading-5"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {poojadetail.templeDetail}
                  </pre>
                </div>
              </div>
            </div>
            <div ref={targetDiv} id="aboutPayment">
              {/* <PaymentCard slug={slug} /> */}
              <div className=" flex flex-col lg:gap-8  lg:px-0 px-3">
                <h2 className=" text-xl lg:text-4xl font-bold bg-gradient-to-r from-orange-600 to-red-500 text-transparent bg-clip-text ">
                  Select pooja package
                </h2>
                <div className=" pt-3 md:flex-row grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7">
                  {/* {
                poojadetail?.packages?.map((data, index)=>{
                    return <PaymentCard slug={poojadetail?.slug} benefit1={data?.benefit1} benefit2={data?.benefit2} benefit3={data?.benefit3} benefit4={data?.benefit4} addPeople={data?.addPeople} id={data._id} index={index} price={data?.price}/>
                })
              } */}

                  {poojadetail?.packages?.map((data, index) => {
                    return (
                      <div
                        onClick={() => {
                          dispatch(setShowFrom(true));
                          setpackageId(data._id);
                        }}
                        className={`${
                          index === 0
                            ? " border-orange-500 text-orange-500"
                            : index === 1
                            ? " border-blue-500 text-blue-600"
                            : index === 2
                            ? " border-red-500 text-red-600"
                            : " border-purple-500 text-purple-600"
                        } flex flex-col  gap-3 border rounded-xl cursor-pointer hover:scale-105  justify-between transition-all pb-3 flex-1 lg:w-[310px] w-full duration-500 min-h-[500px]`}
                      >
                        <div
                          className=" flex flex-col items-center p-3 clear-start h-full gap-3 rounded-xl justify-between"
                          style={
                            index === 0
                              ? containerStyles
                              : index === 1
                              ? containerStyles2
                              : index === 2
                              ? containerStyles3
                              : containerStyles4
                          }
                        >
                          <div className=" w-full flex flex-col items-center p-3 gap-3 rounded-xl">
                            <p className=" text-3xl font-semibold">
                              ₹ {data.price}
                            </p>
                            <div className=" flex flex-col items-center">
                              <p className=" text-xl font-semibold">
                                {data.title}
                              </p>
                              <p className=" text-gray-500 font-semibold">
                                Puja for{data?.addPeople}
                                {data?.addPeople > 1 ? "Person" : "People"}
                              </p>
                            </div>
                            <hr className=" w-full h-[2px] bg-gray-500" />
                            <ul className=" flex flex-col gap-3 text-gray-600 w-full items-start px-6">
                              {data.benefit1 && (
                                <li className=" flex items-center gap-2">
                                  <FaDotCircle className=" text-blue-600" />
                                  {data.benefit1}
                                </li>
                              )}
                              {data.benefit2 && (
                                <li className=" flex items-center gap-2">
                                  <FaDotCircle className=" text-blue-600" />
                                  {data.benefit2}
                                </li>
                              )}
                              {data.benefit3 && (
                                <li className=" flex items-center gap-2">
                                  <FaDotCircle className=" text-blue-600" />
                                  {data.benefit3}
                                </li>
                              )}

                              {data.benefit4 && (
                                <li className=" flex items-center gap-2">
                                  <FaDotCircle className=" text-blue-600" />
                                  {data.benefit4}
                                </li>
                              )}
                            </ul>
                          </div>
                          <button
                            className=" w-full p-2 rounded-xl text-white font-bold cursor-pointer gradientButton relative overflow-hidden flex gap-2 items-center justify-center"
                            onClick={() => console.log(data._id)}
                          >
                            <div className=" w-[80px] h-[150px] bg-white -top-2 -left-2 rotate-45 absolute -skew-x-[45deg] opacity-40 animateButton"></div>
                            Participate <FaArrowRight />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className=" w-full mt-20 max-w-screen-xl"
              id="reviews"
              ref={aboutReviewsDiv}
            >
              <div className=" w-full   flex items-start gap-5 flex-col">
                <h2 className="text-[18px] md:text-[24px] font-bold bg-gradient-to-r from-orange-600 to-red-500 text-transparent bg-clip-text ">
                  What devotees Say about Devsthana Puja ?
                </h2>
                <p className=" text-lg  text-gray-500">
                  Reviews and Ratings from our customers who performed online
                  Puja with us.
                </p>
              </div>
              <div className=" w-full">
                <GetRating />
              </div>
            </div>
            <div className=" w-full mt-20 max-w-screen-xl">
              <div className=" w-full   flex items-start gap-5 flex-col">
                <h2 className="text-[18px] md:text-[24px] font-bold bg-gradient-to-r from-orange-600 to-red-500 text-transparent bg-clip-text ">
                  Frequently asked Questions
                </h2>
              </div>

              <div className=" w-full mt-5" id="FAQS" ref={aboutFAQSDiv}>
                <FAQS />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:hidden w-full p-2 sticky bottom-1 bg-white left-0  z-[50000000]">
        <button
          className="lg:hidden w-full p-2 sticky bottom-1 left-0 rounded-md text-white font-bold cursor-pointer gradientButton overflow-hidden flex gap-2 items-center justify-center"
          onClick={() => handleScrolltoDiv()}
        >
          <div className=" w-[80px] h-[150px] bg-white -top-2 -left-2 rotate-45 absolute -skew-x-[45deg] opacity-40 animateButton"></div>
          Book Now <FaArrowRight />
        </button>
      </div>
      <Footer />

      {showForm && <FormModal slug={slug} id={packageId} />}
    </>
  );
};

export default PoojaById;
