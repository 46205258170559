import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPooja, setSteps } from '../../../../Slices/PoojaSlice'
import { apiConnector } from '../../../../Services/ApiConnector'
import { pujaEndPoints } from '../../../../Services/AllApi'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'


const Step3 = () => {
  const {pooja} = useSelector((state)=> state.pooja)
  const [Publish, setPublish] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {CREATE_PUJA_API} = pujaEndPoints
  const {token} = useSelector((state)=> state.auth)

  const goBackHandler = ()=>{
    dispatch(setPooja(null))
    dispatch(setSteps(1))
}




const handleSubmit = async()=>{
  const toastId = toast.loading("Please wait")
  const { title, description, address, date, templeDetail, benefit1,
    benefit2,
    benefit3,
    benefit4,
    benefit5,
    benefit6 ,
    benefitDesc1,
    benefitDesc2,
    benefitDesc3,
    benefitDesc4,
    benefitDesc5,
    benefitDesc6, slug, image1, image2, image3, image4, poojaPackage,templeName,benefitImage1, benefitImage2, benefitImage3, benefitImage4, benefitImage5, benefitImage6 } = pooja;
    console.log(pooja)

  const formData = new FormData();
formData.append('title', title);
formData.append('description', description);
formData.append('address', address);
formData.append('date', date);
formData.append('templeDetail', templeDetail);
formData.append('slug', slug);
formData.append('poojaPackage', poojaPackage);
formData.append('templeName', templeName);
formData.append('image1', image1);
formData.append('image2', image2);
formData.append('image3', image3);
formData.append('image4', image4);
formData.append('benefit1', benefit1);
formData.append('benefit2', benefit2);
formData.append('benefit3', benefit3);
formData.append('benefit4', benefit4);
formData.append('benefit5', benefit5);
formData.append('benefit6', benefit6);
formData.append('benefitDesc1', benefitDesc1);
formData.append('benefitDesc2', benefitDesc2);
formData.append('benefitDesc3', benefitDesc3);
formData.append('benefitDesc4', benefitDesc4);
formData.append('benefitDesc5', benefitDesc5);
formData.append('benefitDesc6', benefitDesc6);
formData.append('benefitImage1', benefitImage1);
formData.append('benefitImage2', benefitImage2);
formData.append('benefitImage3', benefitImage3);
formData.append('benefitImage4', benefitImage4);
formData.append('benefitImage5', benefitImage5);
formData.append('benefitImage6', benefitImage6);


  try {
    const response = await apiConnector("POST", CREATE_PUJA_API, formData,{
      "Content-Type": "multipart/form-data",
     Authorization:`Bearer ${token}`
  })

    toast.success("Created successfull")
    dispatch(setPooja(null))
    navigate("/dashboard/my-pooja")
    dispatch(setSteps(1))
  } catch (error) {
    console.log(error)
  }
  toast.dismiss(toastId)
}

  return (
    <div className=' flex w-full items-center justify-center flex-col gap-20 mt-20'>
      <div className=' flex items-center gap-3 flex-col border border-gray-400 p-3 rounded-xl'>
        <div className=' flex items-center gap-3'>
        <input type="checkbox" className=' w-[20px] h-[20px]' onClick={()=> setPublish(!Publish)} />
        <p>Do you want to publish?</p>
        </div>
        <p>Please check carefully all details before publish!</p>
      </div>

      <div className=' w-full flex items-center justify-between'>
          <button
          type='button'
          className=' px-3 p-1 rounded-xl bg-orange-500'
          onClick={()=> goBackHandler()}
          >
          Go back
          </button> 
         {
          Publish === true &&  <button
          type='button'
          className=' px-3 p-1 rounded-xl bg-orange-500'
            onClick={()=> handleSubmit()}
          >
          Submit
          </button>
         }
       </div>
    </div>
  )
}

export default Step3