export const NavLinks = [
    {
        id:1,
        title:"Home",
        path:"/"
    },
     {
        id:2,
        title:"Puja",
        path:"/puja"
    },
    {
        id:3,
        title:"Astrology",
        path:"/astrology"
    },  {
        id:4,
        title:"Temples",
        path:"/temples"
    },
    {
        id:5,
        title:"Library",
    },
    {
        id:6,
        title:"Puja Articles",
        path:"/puja-articles"
    },
    {
        id:7,
        title:"Contact",
        path:"/contact-us"
    }
]