import React from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { apiConnector } from "../Services/ApiConnector";
import { authEndPoints } from "../Services/AllApi";
import Footer from "../component/common/Footer";
import articleBg from "../Assets/articleBg.jpg";
import { Helmet } from "react-helmet-async";
import TextAnimation from "../component/core/Homecomponent/TextAnimation";
import { Link } from "react-router-dom";

const ContactUs = () => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { CONTACT_US_API } = authEndPoints;
  const onSubmit = async (data) => {
    const { fullName, email, phoneNum, message } = data;
    const toastid = toast.loading("Please wait..");
    try {
      await apiConnector("POST", CONTACT_US_API, {
        fullName,
        email,
        phoneNum,
        message,
      });
      toast.success("Thank you! We will contact you soon.");
    } catch (error) {
      console.log(error);
    }
    toast.dismiss(toastid);
  };
  return (
    <>
      <Helmet>
        <title>Devsthana - Contact Us</title>
        <meta
          name="description"
          content="Get in touch with Devsthana for all your inquiries, feedback, and queries. Our dedicated team is here to assist you with personalized assistance and guidance. Whether you have questions about our services or want to share your experience, we're here to listen and help."
        />
        <link rel="canonical" href="/contact-us" />
        <meta property="og:title" content="Devsthana - Contact Us" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.devsthana.com/contact-us"
        />
      </Helmet>
      <div className=" relative w-full mx-auto flex items-center justify-center flex-col lg:pt-0 pt-10">
        <img
          src={articleBg}
          alt="contact"
          className=" max-h-[400px] w-full object-cover opacity-75"
        />
        <TextAnimation />
        <div className=" flex items-center flex-col gap-4 absolute">
          <p className=" text-white">Still need help?</p>
          <p className=" lg:text-5xl text-2xl text-red-600 font-bold">
            Get in touch with us
          </p>
        </div>
      </div>
      <div className=" w-full flex flex-col items-center justify-center mt-32">
        <form
          className=" w-full lg:w-[40%] flex flex-col gap-3 p-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className=" flex flex-col gap-2 px-2">
            <label className=" text-orange-500 font-semibold">
              Enter Full name<sup className=" text-red-500 text-sm">*</sup>
            </label>
            <input
              type="text"
              className=" w-full border-2 border-orange-500  rounded-xl p-2"
              placeholder="Enter full name here"
              {...register("fullName", { required: true })}
            />
            {errors.fullName && (
              <span className=" text-red-500">fullName is required</span>
            )}
          </div>
          <div className=" flex flex-col gap-2 px-2">
            <label className=" text-orange-500 font-semibold">
              Enter email<sup className=" text-red-500 text-sm">*</sup>
            </label>
            <input
              type="email"
              className=" w-full border-2 border-orange-500  rounded-xl p-2"
              placeholder="Enter email here"
              {...register("email", { required: false })}
            />
          </div>
          <div className=" flex flex-col gap-2 px-2">
            <label className=" text-orange-500 font-semibold">
              Enter phone number
              <sup className=" text-red-500 text-sm">*</sup>
            </label>
            <input
              type="number"
              placeholder="Whatsapp Number"
              className=" w-full border-2 border-orange-500  rounded-xl p-2"
              {...register("phoneNum", {
                required: {
                  value: true,
                  message: "Please enter Phone Number",
                },
                maxLength: { value: 10, message: "Invalid Phone Number" },
                minLength: { value: 8, message: "Invalid Phone Number" },
              })}
            />
            {errors.phoneNum && (
              <span className=" text-red-500">phoneNum is required</span>
            )}
          </div>
          <div className=" flex flex-col gap-2 px-2">
            <label className=" text-orange-500 font-semibold">
              Enter message
              <sup className=" text-red-500 text-sm">*</sup>
            </label>
            <textarea
              type="text"
              className=" w-full border-2 border-orange-500  rounded-xl p-2"
              placeholder="Enter address here"
              {...register("message", { required: false })}
            />
          </div>

          <div className=" w-full items-center flex justify-center p-2">
            <button className=" bg-orange-500 px-5 py-2 rounded-xl hover:scale-105  bg-gradient-to-r from-orange-500 to-red-500 text-white transition-all duration-200">
              Submit
            </button>
          </div>
        </form>
        <div className=" flex flex-col gap-1 items-center px-3">
          <div className=" text-black text-center">
            If you have any questions or need assistance, please feel free to
            reach out to us at{" "}
            <a
              href="mailto:devsthana@gmail.com"
              className=" text-cyan-700 underline"
            >
              devsthana@gmail.com
            </a>
            . We are here to help!
          </div>

          <Link to={"tel:+917880118870"} className=" text-[14px] font-semibold">
            +91 7880118870
          </Link>
          <Link to={"tel:+917880118870"} className=" text-[18px] text-gray-700">
            You can call us from 10 AM - 8 PM
          </Link>
        </div>
        <p>
          Address - 03/1 sundar nagar, Indore, PO: Vijay Nagar, District:
          Indore, State: Madhya Pradesh, PIN Code: 452010
        </p>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
