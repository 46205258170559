import React from 'react'
import { useForm } from 'react-hook-form'
import { apiConnector } from '../../../../Services/ApiConnector'
import { articleEndPoint, pujaEndPoints } from '../../../../Services/AllApi'
import toast from 'react-hot-toast'

const CreateFaq = () => {
    const {register, setValue, handleSubmit, formState:{errors}}= useForm()
    const {CREATE_FAQS_API} = pujaEndPoints
    const {CREATE_ARTICLE_FAQ_API} = articleEndPoint
    const onSubmit = async(data)=>{

        const {ques, ans} = data
        const toastId = toast.loading("Please wait")
        try {
            const response = await apiConnector("POST", CREATE_FAQS_API, {ques, ans})
            if(!response.data.success){
                toast.error("Couldn't create faq")
                return
            }
            toast.success("Created successfully")

            
        } catch (error) {
            console.log("Error")
        }
        toast.dismiss(toastId)
    }


   
  return (
    <div className=' w-full flex items-center justify-center flex-col gap-4'>
    <h1 className=' text-xl lg:text-3xl font-bold uppercase'>Add puja faq here</h1>
    <form className=' w-full lg:w-[40%] flex flex-col gap-3 p-3' onSubmit={handleSubmit(onSubmit)}>
  
    <div className=" flex flex-col gap-2 px-2" >
      <label className=" text-orange-500 font-semibold">
        Enter the question<sup className=" text-red-500 text-sm">*</sup>
      </label>
      <input
        type="text"
        className=" w-full border-2 border-orange-500  rounded-xl p-2"
        placeholder="Enter question here"
        {...register("ques", { required: true })}
      />
      {errors.ques && (
        <span className=" text-red-500">Question is required</span>
      )}
      </div>
      <div className=" flex flex-col gap-2 px-2">
      <label className=" text-orange-500 font-semibold">
        Enter the solution<sup className=" text-red-500 text-sm">*</sup>
      </label>
      <textarea
        type="text"
        className=" w-full border-2 border-orange-500  rounded-xl p-2"
        placeholder="Enter solution here"
        {...register("ans", { required: true })}
      />
      {errors.ans && (
        <span className=" text-red-500">Solution is required</span>
      )}
      </div>
      <div className=" w-full items-center flex justify-center p-2">
      <button className=" bg-orange-500 px-5 py-2 rounded-xl hover:bg-orange-600 transition-all duration-200">Next</button>
    </div>
    </form>


  
</div>
  )
}

export default CreateFaq